import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Navbar from '../Navbar';
import Footer from '../Footer';
import { CartContext } from '../../contexts/CartContext';

const Cart = () => {
    const { cart, removeFromCart, addToCart, updateQuantity } = useContext(CartContext);
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deletedFromCart, setDeletedFromCart] = useState(false);
    const [isCredentialsLoading, setIsCredentialsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkCredentials = () => {
            const country_id = localStorage.getItem('country_id');
            if (!country_id) {
                navigate('/country');
                return false;
            }
            const dealer_id = localStorage.getItem('dealer_id');
            if (!dealer_id) {
                navigate('/dealer');
                return false;
            }
            return true;
        };

        if (checkCredentials()) {
            setIsCredentialsLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        if (!isCredentialsLoading) {
            loadCartItems();
        }
    }, [cart, isCredentialsLoading]);

    const loadCartItems = async () => {
        setLoading(true);
        const dealer_id = localStorage.getItem('dealer_id');
        let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
        const config = {
            headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en' }
        };

        try {
            const promises = Object.entries(cart).map(([key, value]) => 
                axios.get(`${process.env.REACT_APP_FORD_API_URL}/api/v1/accessories/${key}?dealer_id=${dealer_id}`, config)
                .then(res => ({
                    id: value.id,
                    quantity: value.quantity,
                    accessory: res.data
                }))
            );

            const items = await Promise.all(promises);
            setCartItems(items);
        } catch (error) {
            console.error('Error fetching cart items:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddQuantity = (id) => {
        const newCartItems = cartItems.map(item =>
            item.id === id ? { ...item, quantity: item.quantity + 1 } : item
        );
        setCartItems(newCartItems); // Update local state
        updateQuantity(id, newCartItems.find(item => item.id === id).quantity); // Update global cart context
    };

        
    const handleMinusQuantity = (id) => {
        const newCartItems = cartItems.map(item =>
            item.id === id && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item
        );
        setCartItems(newCartItems);
        updateQuantity(id, newCartItems.find(item => item.id === id).quantity); // Update global cart context
    };

    const handleDeleteItem = (id) => {
        // event.preventDefault(); // Prevent default link behavior
        removeFromCart(id);
        setCartItems(prevItems => prevItems.filter(item => item.id !== id));
    };

    const calculateSubtotal = () => {
        return cartItems.reduce((total, item) => total + (item.accessory.totalBeforeTax * item.quantity), 0).toFixed(2);
    };

    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    if (isCredentialsLoading || loading) {
        return (
            <>
                <Navbar />
                <div className="loader-container">
                    <div className="loader"><img src='../images/Rolling@1x-1.0s-200px-200px.gif' width={100} height={100}/></div>
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
        <Navbar />
        {deletedFromCart && (
         <div className="cart-updated-msg">
             <div className="container">
                 <p>
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                         <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                     </svg>
                     <span language="ar">تم حذف العنصر من سلة التسوق</span>
                     <span language="en">Item deleted from cart</span>
                 </p>
             </div>
         </div>
        )}
        <div className="container">
            <div className="cart">
                <div className="breadcrumb d-none d-md-block">
                    <Link to="/cart">
                        <span language="ar">عربة التسوق</span>
                        <span language="en">Cart</span>
                    </Link>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                    <Link to="/cart">
                        <span language="ar">مراجعة عربة التسوق</span>
                        <span language="en">Review Cart</span>
                    </Link>
                </div>

                <h2>
                    <span language="ar">مراجعة عربة التسوق</span>
                    <span language="en">Review Cart</span>
                </h2>

                {cartItems.length > 0 ? (
                    <div>
                        <div className="products">
                            {cartItems.map((product) => (
                                <div className="product" key={product.id}>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            {product.accessory.media && product.accessory.media.find(media => media.collection_name === "print-image") ? (
                                                <img src={product.accessory.media.find(media => media.collection_name === "print-image").original_url} alt={product.accessory.accessory_name} />
                                            ) : (
                                                <img src="../images/default.jpg" alt="Default" />
                                            )}
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <p className="name">{product.accessory.accessory_name}</p>
                                            <p className="partno">
                                                <span language="ar">رقم الجزء</span>
                                                <span language="en">Part Number</span>: {product.accessory.part_number}
                                            </p>
                                            <p className="price">{ formatNumber(product.accessory.totalBeforeTax.toFixed(2)) } {product.accessory.currency}</p>
                                            <div className="quantity">
                                                <p className="heading">
                                                    <span language="ar">الكمية</span>
                                                    <span language="en">Quantity</span>
                                                </p>
                                                <div className="buttons">
                                                    <span className="minus" onClick={() => handleMinusQuantity(product.id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash" viewBox="0 0 16 16">
                                                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"/>
                                                        </svg>
                                                    </span>
                                                    <span className="value">{product.quantity}</span>
                                                    <span className="plus" onClick={() => handleAddQuantity(product.id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="remove">
                                                <a href="#" onClick={() => handleDeleteItem(product.id)}>
                                                    <span language="ar">حذف</span>
                                                    <span language="en">Delete</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <p className="subtotal">
                            <span language="ar">المجموع الفرعي</span>
                            <span language="en">Subtotal</span>
                            : {formatNumber(calculateSubtotal())} {cartItems[0]?.accessory.currency}
                        </p>

                        <div className="ford-btn">
                            <Link to="/checkout">
                                <span language="ar">السداد مع الخروج</span>
                                <span language="en">Checkout</span>
                            </Link>
                        </div>
                    </div>
                ) : (
                    <p className="empty-cart">Your cart is empty.</p>
                )}
            </div>
        </div>
        <Footer />
        </>
    );
};

export default Cart;
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../sections/dealer/Dealer.css';
import Navbar from '../Navbar';
import Footer from '../Footer';
import DealerList from '../sections/dealer/DealerList';

function Dealer() {

    const navigate = useNavigate();

    useEffect(() => {
        const country_id = localStorage.getItem('country_id');
        if (!country_id) {
            navigate('/country');
        }
    }, []);

    return (
        <>
        <div>
            <div className="page-dealer">
                <Navbar />
                <div className="page-content page-ds">
                    <div className="container">
                        <h3>
                            <span language="ar">الوكالة</span>
                            <span language="en">Dealership</span>
                        </h3>
                        <h4>
                            <span language="ar">اختيار وكالة</span>
                            <span language="en">SELECT A DEALERSHIP</span>
                        </h4>
                        <DealerList />
                    </div>
                </div>
                <Footer />
            </div>
        </div>
        </>
    );
}

export default Dealer;
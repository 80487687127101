import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';
import { CartContext } from '../contexts/CartContext';
import './Navbar.css';

function Navbar() {
    const { cart } = useContext(CartContext);
    const [cartCount, setCartCount] = useState(0);
    const navigate = useNavigate();

    const [input, setInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [countryChange, setCountryChange] = useState(false);
    const [dealerChange, setDealerChange] = useState(false);
    const [appName, setAppName] = useState('');
    const [dealerName, setDealerName] = useState('');
    const [categories, setCategories] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('language') || 'en');
    const [isLoading, setIsLoading] = useState(false);

    const searchRef = useRef(null);

    useEffect(() => {
        const count = Object.values(cart).reduce((acc, item) => acc + item.quantity, 0) || '';
        setCartCount(count);
    }, [cart]);

    useEffect(() => {
        setAppName(process.env.REACT_APP_WHICH_APP);
        fetchDealerName();
        fetchCategories();
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        // document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    
    const handleCategoryClick = (categoryId, categoryName) => {
        localStorage.setItem('selectedCategory', categoryId);
        localStorage.setItem('selectedCategoryName', categoryName);
        if(categoryId == 'new'){
            localStorage.removeItem('selectedModel');
            localStorage.removeItem('selectedYear');
        }
        // Force a page reload to ensure the SearchForm component updates
        window.location.href = '/accessory';
    };

    // const handleClickOutside = (event) => {
    //     if (searchRef.current && !searchRef.current.contains(event.target)) {
    //         setShowSuggestions(false);
    //     }
    // };

    const handleLanguageChange = (lang) => {
        localStorage.setItem('language', lang);
        setCurrentLanguage(lang);
        window.location.reload();
    };

    const trimDealerName = (name) => {
        if (name.length <= 20) return name;
        return name.slice(0, 20) + '...';
    };

    const fetchDealerName = async () => {
        const dealer_id = localStorage.getItem('dealer_id');
        if (dealer_id) {
            let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
            const config = {
                headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en' }
            };

            try {
                const response = await axios.get(`${process.env.REACT_APP_FORD_API_URL}/api/v1/dealerships/${dealer_id}`, config);
                const dealerTradeName = response.data.dealer?.trade_name || 'Unknown Dealer';
                if (dealerTradeName === 'Unknown Dealer') {
                navigate('/dealer');
                }
                setDealerName(dealerTradeName);
                } catch (error) {
                console.error('Error fetching dealer details:', error);
                setDealerName('Unknown Dealer');
                }
                }
                };

                const fetchCategories = async () => {
                const dealer_id = localStorage.getItem('dealer_id');
                let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
                const config = {
                headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en' }
                };

            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_FORD_API_URL}/api/v1/categories?dealer_id=${dealer_id}&brand_id=${process.env.REACT_APP_BRAND_ID}&market_id=${process.env.REACT_APP_MARKET_ID}`,
                    config
                );
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                setLoadingCategories(false);
            }
        };

        const slugify = (text) => {
            return text.toString().toLowerCase()
                .replace(/\s+/g, '-')
                .replace(/[^\p{L}\p{N}\-]+/gu, '')
                .replace(/\-\-+/g, '-')
                .replace(/^-+/, '')
                .replace(/-+$/, '');
        };

        const handleChange = (e) => {
            const value = e.target.value;
            setInput(value);
            updateSuggestions(value);
        };

        const updateSuggestions = (input) => {
            setIsLoading(true);
            const dealer_id = localStorage.getItem('dealer_id');
            let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
            const config = {
                headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en' }
            };

            if (input.length > 0) {
                axios.get(
                    `${process.env.REACT_APP_FORD_API_URL}/api/v1/accessories/filter?term=${input}&per_page=10&dealer_id=${dealer_id}&brand_id=${process.env.REACT_APP_BRAND_ID}&market_id=${process.env.REACT_APP_MARKET_ID}`,
                    config
                )
                    .then(res => {
                    let suggestions = res.data.data.map(accessory => ({
                        id: accessory.id,
                        text: `${accessory.part_number} - ${accessory.accessory_name}`
                    }));
                    setSuggestions(suggestions);
                    setShowSuggestions(true);
                    setIsLoading(false);
                })
                    .catch(error => {
                    console.error('Error fetching suggestions:', error);
                    setIsLoading(false);
                });
            } else {
                setSuggestions([]);
                setShowSuggestions(false);
                setIsLoading(false);
            }
        };

        const handleSuggestionClick = (suggestion) => {
            console.log(suggestion);
            setInput(suggestion.text);
            setShowSuggestions(false);

            // Close the mobile menu if it's open
            const mobileMenu = document.getElementById('navbarTogglerDemo01');
            if (mobileMenu && mobileMenu.classList.contains('show')) {
                // Find the toggle button and click it to close the menu
                const toggleButton = document.querySelector('[data-bs-target="#navbarTogglerDemo01"]');
                if (toggleButton) {
                    toggleButton.click();
                }
            }
            console.log(suggestion);

            // Navigate to the accessory page
            navigate('/accessory/' + suggestion.id + '/' + slugify(suggestion.text));
        };

        const handleCountryChangeClick = (event) => {
            if (Object.keys(cart).length !== 0) {
                event.preventDefault();
                setCountryChange(true);
            }
        };

        const handleCountryChangeCancelClick = () => {
            setCountryChange(false);
        };

        const handleDealerChangeClick = (event) => {
            if (Object.keys(cart).length !== 0) {
                event.preventDefault();
                setDealerChange(true);
            }
        };

        const handleDealerChangeCancelClick = () => {
            setDealerChange(false);
        };

        return (
            <>
            {appName === "Lincoln" &&
             <div className="d-none d-xl-block">
                 <nav className="navbar navbar-expand-lg sticky-top">
                     <div className="container px-0">
                         <div className="row w-100">
                             <div className="col-auto">
                                 <Link className="" to="/">
                                     <img className="ford" src={`/images/${appName.toLowerCase()}.svg`} alt={appName} height="45" />
                                 </Link>
                             </div>

                             <div className="col d-flex justify-content-end">
                                 <ul className="navbar-nav">
                                     <li className="nav-item dropdown">
                                         <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                             <span language="ar">الملحقات</span>
                                             <span language="en">Accessories</span>
                                         </a>
                                         <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                             {loadingCategories ? (
                                                 <li><span className="dropdown-item"><a>Loading...</a></span></li>
                                             ) : (
                                                 <>
                                                 {categories.map((category) => (
                                                  <li key={category.id}>
                                                      <Link 
                                                          to="/accessory" 
                                                          className="dropdown-item"
                                                          onClick={() => handleCategoryClick(category.id, category.name)}
                                                          >
                                                          {category.name}
                                                      </Link>
                                                  </li>
                                                  ))}
                                                 <li>
                                                     <Link 
                                                         to="/accessory" 
                                                         className="dropdown-item"
                                                         onClick={() => handleCategoryClick('new', currentLanguage === 'en' ? 'New Accessories' : 'ملحقات جديدة>')}
                                                         >
                                                         <span language="ar">ملحقات جديدة</span>
                                                         <span language="en">New Accessories</span>
                                                     </Link>
                                                 </li>
                                                 </>
                                             )}
                                         </ul>
                                     </li>
                                     <li className="nav-item">
                                         <NavLink to="/faq" className="nav-link">
                                             <span language="ar">الأسئلة الشائعة</span>
                                             <span language="en">FAQ</span>
                                         </NavLink>
                                     </li>
                                     <li className="nav-item">
                                         <form className="d-flex" role="search" ref={searchRef}>
                                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                                 <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                             </svg>
                                             <input 
                                                 className="form-control" 
                                                 type="search" 
                                                 placeholder="Search" 
                                                 aria-label="Search" 
                                                 value={input} 
                                                 onChange={handleChange}
                                                 onFocus={() => setShowSuggestions(true)}
                                                 />
                                             {showSuggestions && input.length > 0 && (
                                                 <ul className="suggestions">
                                                     {isLoading ? (
                                                         <li>Loading...</li>
                                                     ) : suggestions.length > 0 ? (
                                                         suggestions.map((suggestion) => (
                                                             <li
                                                                 key={suggestion.id}
                                                                 onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleSuggestionClick(suggestion);
                                                                  }}
                                                                 >
                                                                 {suggestion.text}
                                                             </li>
                                                         ))
                                                     ) : (
                                                         <li>No items found</li>
                                                     )}
                                                 </ul>
                                             )}
                                         </form>
                                     </li>
                                     <li className="nav-item">
                                         <NavLink to="/dealer" className="nav-link" title="Change Dealer" onClick={handleDealerChangeClick}>
                                             <span className="text-nowrap">{trimDealerName(dealerName)}</span>
                                         </NavLink>
                                     </li>
                                     <li className="nav-item language">
                                         <div className="text-nowrap">
                                             <NavLink 
                                                 onClick={() => handleLanguageChange('en')}
                                                 className={currentLanguage === 'en' ? 'active' : ''}
                                                 >
                                                 EN
                                             </NavLink>
                                             <span>|</span>
                                             <NavLink 
                                                 onClick={() => handleLanguageChange('ar')}
                                                 className={currentLanguage === 'ar' ? 'active' : ''}
                                                 >
                                                 عربي
                                             </NavLink>
                                         </div>
                                     </li>
                                     <li className="nav-item cart-icon">
                                         <NavLink to="/cart" className="nav-link" title="Cart">
                                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                                                 <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                                             </svg>
                                             <span className="count">{cartCount}</span>
                                         </NavLink>
                                     </li>
                                     <li className="nav-item">
                                         <NavLink to="/country" className="nav-link" title="Change Country" onClick={handleCountryChangeClick}>
                                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                 <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                                 <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                             </svg>
                                         </NavLink>
                                     </li>
                                 </ul>
                             </div>
                         </div>
                     </div>
                 </nav>
             </div>
            }

            {appName === "Ford" &&
            <div className="d-none d-xl-block">
                <nav className="navbar navbar-expand-lg sticky-top">
                    <div className="container px-0">
                        <div className="row w-100">
                            <div className="col">
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span language="ar">الملحقات</span>
                                            <span language="en">Accessories</span>
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            {loadingCategories ? (
                                                <li><span className="dropdown-item"><a>Loading...</a></span></li>
                                            ) : (
                                                <>
                                                {categories.map((category) => (
                                                 <li key={category.id}>
                                                     <Link 
                                                         to="/accessory" 
                                                         className="dropdown-item"
                                                         onClick={() => handleCategoryClick(category.id, category.name)}
                                                         >
                                                         {category.name}
                                                     </Link>
                                                 </li>
                                                 ))}
                                                <li>
                                                    <Link 
                                                        to="/accessory" 
                                                        className="dropdown-item"
                                                        onClick={() => handleCategoryClick('new', currentLanguage === 'en' ? 'New Accessories' : 'ملحقات جديدة>')}
                                                        >
                                                        <span language="ar">ملحقات جديدة</span>
                                                        <span language="en">New Accessories</span>
                                                    </Link>
                                                </li>
                                                </>
                                            )}
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/faq" className="nav-link">
                                            <span language="ar">الأسئلة الشائعة</span>
                                            <span language="en">FAQ</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/dealer" className="nav-link" title="Change Dealer" onClick={handleDealerChangeClick}>
                                            <span className="text-nowrap">{trimDealerName(dealerName)}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>

                            <div className="col text-center">
                                <Link className="" to="/">
                                    <img className="ford" src={`/images/${appName.toLowerCase()}.svg`} alt={appName} height="45" />
                                </Link>
                            </div>
                            <div className="col">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <form className="d-flex" role="search" ref={searchRef}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                            </svg>
                                            <input 
                                                className="form-control" 
                                                type="search" 
                                                placeholder="Search" 
                                                aria-label="Search" 
                                                value={input} 
                                                onChange={handleChange}
                                                onFocus={() => setShowSuggestions(true)}
                                                />
                                            {showSuggestions && input.length > 0 && (
                                                <ul className="suggestions">
                                                    {isLoading ? (
                                                        <li>Loading...</li>
                                                    ) : suggestions.length > 0 ? (
                                                        suggestions.map((suggestion) => (
                                                            <li
                                                                key={suggestion.id}
                                                                onClick={() => handleSuggestionClick(suggestion)}
                                                                >
                                                                {suggestion.text}
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li>No items found</li>
                                                    )}
                                                </ul>
                                            )}
                                        </form>
                                    </li>
                                    <li className="nav-item language">
                                        <div className="text-nowrap">
                                            <NavLink 
                                                onClick={() => handleLanguageChange('en')}
                                                className={currentLanguage === 'en' ? 'active' : ''}
                                                >
                                                EN
                                            </NavLink>
                                            <span>|</span>
                                            <NavLink 
                                                onClick={() => handleLanguageChange('ar')}
                                                className={currentLanguage === 'ar' ? 'active' : ''}
                                                >
                                                عربي
                                            </NavLink>
                                        </div>
                                    </li>
                                    <li className="nav-item cart-icon">
                                        <NavLink to="/cart" className="nav-link" title="Cart">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                                            </svg>
                                            <span className="count">{cartCount}</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/country" className="nav-link" title="Change Country" onClick={handleCountryChangeClick}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                            </svg>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            }

            <div className="d-block d-xl-none mobile">
                <nav className="navbar navbar-expand-xl">
                    <div className="container-fluid px-0">
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <button 
                                className="navbar-toggler border-0" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#navbarTogglerDemo01" 
                                aria-controls="navbarTogglerDemo01" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation"
                                >
                                <span className="navbar-toggler-icon"></span>
                            </button>


                            <Link className="navbar-brand mx-auto" to="/">
                                <img className="ford" src={`/images/${appName.toLowerCase()}.svg`} alt={appName} height="60" />
                            </Link>

                            <div className="navbar-toggler invisible">
                                <span className="navbar-toggler-icon"></span>
                            </div>
                        </div>

                        <div className="collapse navbar-collapse mt-2" id="navbarTogglerDemo01">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <form className="d-flex" role="search" ref={searchRef}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                        </svg>
                                        <input 
                                            className="form-control" 
                                            type="search" 
                                            placeholder="Search" 
                                            aria-label="Search" 
                                            value={input} 
                                            onChange={handleChange}
                                            onFocus={() => setShowSuggestions(true)}
                                            />
                                        {showSuggestions && input.length > 0 && (
                                            <ul className="suggestions">
                                                {isLoading ? (
                                                    <li>Loading...</li>
                                                ) : suggestions.length > 0 ? (
                                                    suggestions.map((suggestion) => (
                                                        <li test={suggestion.id}
                                                            key={suggestion.id}
                                                            onClick={() => handleSuggestionClick(suggestion)}
                                                            >
                                                            {suggestion.text}
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No items found</li>
                                                )}
                                            </ul>
                                        )}
                                    </form>
                                </li>
                                <li><div className="divider"></div></li>
                                <li className="nav-item access-link">
                                    <HashLink to="/#accessories" className="nav-link" >
                                        <span language="ar">الملحقات</span>
                                        <span language="en">Accessories</span>
                                    </HashLink>
                                </li>
                                <div id="mobNavbarCategoriesList"></div>
                                <li><div className="divider"></div></li>
                                <li className="nav-item">
                                    <NavLink to="/faq" className="nav-link">
                                        <span language="ar">الأسئلة الشائعة</span>
                                        <span language="en">FAQ</span>
                                    </NavLink>
                                </li>
                                <li><div className="divider"></div></li>
                                <li className="nav-item">
                                    <NavLink 
                                        onClick={() => handleLanguageChange('en')}
                                        className={currentLanguage === 'en' ? 'active' : ''}
                                        >
                                        EN
                                    </NavLink>
                                    |
                                    <NavLink 
                                        onClick={() => handleLanguageChange('ar')}
                                        className={currentLanguage === 'ar' ? 'active' : ''}
                                        >
                                        عربي
                                    </NavLink>
                                </li>
                                <li><div className="divider"></div></li>
                                <div className="row icons">
                                    <div className="col-4">
                                        <li className="nav-item cart-icon">
                                            <NavLink to="/cart" className="nav-link" title="Cart">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                                                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                                                </svg>
                                            </NavLink>
                                            <span className="count">{cartCount}</span>
                                        </li>
                                    </div>
                                    <div className="col-4">
                                        <li className="nav-item">
                                            <NavLink to="/country" className="nav-link" title="Change Country" onClick={handleCountryChangeClick}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                </svg>
                                            </NavLink>
                                        </li>
                                    </div>
                                    <div className="col-4">
                                        <li className="nav-item">
                                            <NavLink to="/dealer" className="nav-link" title="Change Dealer" onClick={handleDealerChangeClick}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-shop-window" viewBox="0 0 16 16">
                                                    <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h12V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5m2 .5a.5.5 0 0 1 .5.5V13h8V9.5a.5.5 0 0 1 1 0V13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5a.5.5 0 0 1 .5-.5" />
                                                </svg>
                                            </NavLink>
                                        </li>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>

            {countryChange && 
            <div className="country-change-msg">
                <div className="container">
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                        </svg>
                        <span language="ar">سيتم تحديث أسعار العناصر الموجودة في عربة التسوق عند حدوث أي تغيير في البلد. هل ترغب في الاستمرار؟</span>
                        <span language="en">The prices of the cart items will be refreshed upon change in country. Do you wish to continue?</span>
                        <Link to="/country">
                            <span language="ar">نعم</span>
                            <span language="en">Ok</span>
                        </Link>
                        <HashLink to="#" onClick={handleCountryChangeCancelClick}>                                
                            <span language="ar">يلغي</span>
                            <span language="en">Cancel</span>
                        </HashLink>
                    </p>
                </div>
            </div>
            }
            {dealerChange && 
            <div className="country-change-msg dealer-change-msg">
                <div className="container">
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                        </svg>
                        <span language="ar">سيتم تحديث أسعار العناصر الموجودة في عربة التسوق عند تغيير التاجر. هل ترغب في الاستمرار؟</span>
                        <span language="en">The prices of the cart items will be refreshed upon change in dealer. Do you wish to continue?</span>
                        <Link to="/dealer">
                            <span language="ar">نعم</span>
                            <span language="en">Ok</span>
                        </Link>
                        <HashLink to="#" onClick={handleDealerChangeCancelClick}>                                
                            <span language="ar">يلغي</span>
                            <span language="en">Cancel</span>
                        </HashLink>
                    </p>
                </div>
            </div>
            }
            </>
        );
    }

    export default Navbar;
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Navbar from '../Navbar';
import Footer from '../Footer';

import Banner from '../sections/home/Banner';
import MainForm from '../sections/home/Mainform';
import Categories from '../sections/home/Categories';

function Home() {

    const navigate = useNavigate();

    useEffect(() => {
        const country_id = localStorage.getItem('country_id');
        if (!country_id) {
            navigate('/country');
        }else{
            const dealer_id = localStorage.getItem('dealer_id');
            if (!dealer_id) {
                navigate('/dealer');
            }
        }
    }, []);

    return (
        <>
        <Navbar />

        <div className="page-home">
            <Banner />
            <MainForm />
            <Categories />
        </div>

        <Footer />
        </>
    );
}

export default Home;
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Navbar from '../Navbar';
import Footer from '../Footer';
import New from '../sections/category/New';

function Category() {  

    const navigate = useNavigate();

    useEffect(() => {
        const country_id = localStorage.getItem('country_id');
        if (!country_id) {
            navigate('/country');
        }else{
            const dealer_id = localStorage.getItem('dealer_id');
            if (!dealer_id) {
                navigate('/dealer');
            }
        }
    }, []);

    return (
        <>
        <div>
            <Navbar />
            <New />
            <Footer />
        </div>
        </>
    );
}

export default Category;
import React from 'react';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'react-external-link';

export default class FooterLinks extends React.Component {

    handleLanguageClick = (language) => {
        console.log(language);
        localStorage.setItem('language', language);
        document.documentElement.lang = language;

        window.location.reload();
    }

    render() {
        return (
            <div className="bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-10">
                            <span>&copy; 2024 
                                <span language="ar">شركة فورد للسيارات</span>
                                <span language="en">Ford Motor Company</span>
                            </span>
                            <Link
                                className="footer-link mx-2"
                                to="/"
                                onClick={() => this.handleLanguageClick('en')}
                            >
                                <span>English</span>
                            </Link>
                            <Link
                                className="footer-link mx-2"
                                to="/"
                                onClick={() => this.handleLanguageClick('ar')}
                            >
                                <span>عربي</span>
                            </Link>
                            <Link className="footer-link mx-2" to="/country">
                                <span language="ar">اختيار دولة</span>
                                <span language="en">Country Select</span>
                            </Link>
                            <ExternalLink className="footer-link mx-2" href="https://fordlifestylecollection.com/" external>
                                <span language="ar">سلع فورد</span>
                                <span language="en">Ford Merchandise</span>
                            </ExternalLink>
                            {process.env.REACT_APP_WHICH_APP === 'Ford' &&
                                <ExternalLink className="footer-link mx-2" href={process.env.REACT_APP_LINCOLN_ACCESSORIES_URL} external>
                                    <span language="ar">Lincoln ملحقات</span>
                                    <span language="en">Lincoln Accessories</span>
                                </ExternalLink>
                            }
                            {process.env.REACT_APP_WHICH_APP === 'Lincoln' &&
                                <ExternalLink className="footer-link mx-2" href={process.env.REACT_APP_FORD_ACCESSORIES_URL} external>
                                    <span language="ar">Ford ملحقات</span>
                                    <span language="en">Ford Accessories</span>
                                </ExternalLink>
                            }
                            <ExternalLink className="footer-link mx-2" href="https://www.me.ford.com/en/qat/privacy-policy/" external>
                                <span language="ar">الخصوصية وبيان إخلاء المسؤولية</span>
                                <span language="en">Privacy & Disclaimer</span>
                            </ExternalLink>
                            {process.env.REACT_APP_WHICH_APP === 'Ford' &&
                                <ExternalLink className="footer-link mx-2" href="https://www.me.ford.com/">
                                    <span language="ar">فورد الشرق الأوسط</span>
                                    <span language="en">Ford Middle East</span>
                                </ExternalLink>
                            }
                            {process.env.REACT_APP_WHICH_APP === 'Lincoln' &&
                                <ExternalLink className="footer-link mx-2" href="https://www.me.lincoln.com/">
                                    <span language="ar">لينكولن الشرق الأوسط</span>
                                    <span language="en">Lincoln Middle East</span>
                                </ExternalLink>
                            }
                        </div>
                        <div className="col-xs-12 col-md-2 text-end">
                            <ExternalLink href={process.env.REACT_APP_WEBSITE_LINK}>
                                <img src={'/images/' + process.env.REACT_APP_WHICH_APP.toLowerCase() + '.svg'} alt={process.env.REACT_APP_WHICH_APP} />
                            </ExternalLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

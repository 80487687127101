import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function AccessoriesDeepLink() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const model = searchParams.get('model');
        const year = searchParams.get('year');
        const language = searchParams.get('language') || 'en';


        // Set the language in localStorage
        localStorage.setItem('language', language);
        localStorage.setItem('selectedModel', model);
        localStorage.setItem('selectedYear', year);

        // Redirect to the Search page
        navigate('/accessory', { 
            state: { 
                selectedModel: model,
                selectedYear: year
            } 
        });
    }, [navigate, location]);

    // Render a loading state while redirecting
    return (
        <div className="loading">
            <p>Loading...</p>
        </div>
    );
}

export default AccessoriesDeepLink;
import React, { createContext, useState, useEffect } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(() => {
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : {};
    });

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const addToCart = (item, quantity) => {
        setCart(prevCart => {
            const updatedCart = { ...prevCart };
            updatedCart[item.id] = {
                id: item.id,
                quantity: (updatedCart[item.id]?.quantity || 0) + quantity,
                           category_id: localStorage.getItem('category_id') || ''
                           };
                           // Update local storage
                           localStorage.setItem('cart', JSON.stringify(updatedCart));
                return updatedCart;
            });
        };

                const updateQuantity = (id, quantity) => {
            setCart(prevCart => ({
                ...prevCart,
                [id]: { ...prevCart[id], quantity }
            }));
        };

        const removeFromCart = (id) => {
            setCart(prevCart => {
                const newCart = { ...prevCart };
                delete newCart[id];
                return newCart;
            });
        };

        const clearCart = () => {
            setCart({});
        }

        return (
            <CartContext.Provider value={{ cart, addToCart, updateQuantity, removeFromCart, clearCart }}>
                {children}
            </CartContext.Provider>
        );
    };
import React from 'react';
import FooterLinks from '../components/sections/home/FooterLinks';

function Footer() {
    const handleSocialClick = (e, href) => {
        e.preventDefault();
        const language = localStorage.getItem('language') || 'en';
        const message = language === 'ar'
        ? 'الرجاء التأكيد إذا كنت ترغب في مغادرة هذه الصفحة.'
        : 'Please confirm if you want to leave this page.';

        if (window.confirm(message)) {
            window.open(href, '_blank', 'noopener,noreferrer');
        }
    };

    const SocialLink = ({ href, children }) => (
        <a href={href} onClick={(e) => handleSocialClick(e, href)}>
            {children}
        </a>
    );

    return (
        <div className="footer">
            <div className="follow text-end">
                <div className="container">
                    <p>
                        <span language="en">Follow {process.env.REACT_APP_WHICH_APP}</span>
                        <span language="ar">تابعوا فورد</span>
                        <SocialLink href={process.env.REACT_APP_FB_LINK}>
                            <img src="/images/facebook.png" />
                        </SocialLink>
                        <SocialLink href={process.env.REACT_APP_X_LINK}>
                            <img src="/images/twitter.png" />
                        </SocialLink>
                        <SocialLink href={process.env.REACT_APP_YT_LINK}>
                            <img src="/images/youtube.png" />
                        </SocialLink>
                        <SocialLink href={process.env.REACT_APP_INSTA_LINK}>
                            <img src="/images/instagram.png" />
                        </SocialLink>
                        <SocialLink href={process.env.REACT_APP_LINKEDIN_LINK}>
                            <img src="/images/linkedin.png" />
                        </SocialLink>
                    </p>
                </div>
            </div>

            <FooterLinks />
        </div>
    );
}

export default Footer;
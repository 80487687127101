import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';

import Navbar from '../Navbar';
import Footer from '../Footer';
import { CartContext } from '../../contexts/CartContext';
import { ExternalLink } from 'react-external-link';

function Accessory() {
    const [accessory, setAccessory] = useState([]);
    const [category, setCategory] = useState([]);
    const [quantity, setQuantity] = useState('1');
    const [loading, setLoading] = useState(false);
    const [addedToCart, setAddedToCart] = useState(false);
    const [mainImage, setMainImage] = useState('');
    const [magnifierVisible, setMagnifierVisible] = useState(false);
    const [magnifierPosition, setMagnifierPosition] = useState({ x: 0, y: 0 });
    const imageRef = useRef(null);

    const { addToCart } = useContext(CartContext);
    const { id: accessoryId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setAddedToCart(false);
        setLoading(true);

        const country_id = localStorage.getItem('country_id');
        const dealer_id = localStorage.getItem('dealer_id');
        const brand_id = localStorage.getItem('brand_id');
        const category_id = localStorage.getItem('category_id') || '';
        

        if (!country_id) {
            navigate('/country');
        } else if (!dealer_id) {
            navigate('/dealer');
        } else {
            let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
            const config = {
                headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
            };

            const fetchData = async () => {
                try {
                    const accessoryRes = await axios.get(`${process.env.REACT_APP_FORD_API_URL}/api/v1/accessories/${accessoryId}?dealer_id=${dealer_id}&brand_id=${process.env.REACT_APP_BRAND_ID}&category_id=${category_id}`, config);
                    setAccessory(accessoryRes.data);
                    console.log(accessoryRes.data);

                    // Set the main image
                    const printImage = accessoryRes.data.media.find(media => media.collection_name === "print-image");
                    setMainImage(printImage ? printImage.original_url : '../images/default.jpg');

                    const categoryId = accessoryRes.data.categories[0].id;
                    const categoryRes = await axios.get(`${process.env.REACT_APP_FORD_API_URL}/api/v1/categories/${categoryId}`, config);
                    setCategory(categoryRes.data);
                } catch (error) {
                    console.error('Error fetching accessory or category:', error);
                    // Handle errors, possibly update state with error information or show an error message
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [accessoryId, navigate]);

    const handleAddQuantity = () => {
        setQuantity(prevQuantity => {
            const newQuantity = parseInt(prevQuantity) + 1;
            return newQuantity <= 50 ? newQuantity.toString() : prevQuantity;
        });
    };

    const handleMinusQuantity = () => {
        setQuantity(prevQuantity => {
            const newQuantity = parseInt(prevQuantity) - 1;
            return newQuantity >= 1 ? newQuantity.toString() : prevQuantity;
        });
    };

    const handleAddToCart = () => {
    setLoading(true);
    setAddedToCart(false);

    const itemToAdd = {
        id: accessory.id,
        quantity: parseInt(quantity, 10),
        category_id: localStorage.getItem('category_id') || ''
    };

    addToCart(itemToAdd, parseInt(quantity, 10));
    setLoading(false);
    setAddedToCart(true);

    // Update local storage directly
    const currentCart = JSON.parse(localStorage.getItem('cart') || '{}');
    currentCart[accessory.id] = {
        ...itemToAdd,
        category_id: localStorage.getItem('category_id') || ''
    };
    localStorage.setItem('cart', JSON.stringify(currentCart));
};

    const handleMouseMove = (e) => {
        if (imageRef.current) {
            const { left, top, width, height } = imageRef.current.getBoundingClientRect();
            const x = ((e.pageX - left) / width) * 100;
            const y = ((e.pageY - top) / height) * 100;
            setMagnifierPosition({ x, y });
        }
    };

    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const slugify = (text) => {
        if (text == null) return ''; // Return empty string if text is null or undefined
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\p{L}\p{N}\-]+/gu, '')
            .replace(/\-\-+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    }

    const renderFitmentGuides = () => {
        const fitmentGuide = accessory.media.find(media => media.collection_name === "fitment-guide");

        if(fitmentGuide) {

            return (
                <>
                <div className="fitment">
                    <ExternalLink href={fitmentGuide.original_url}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z"/>
                        </svg>
                        <span language='en'>Download installation/fitment instructions</span>
                        <span language='ar'>تنزيل تعليمات التركيب / الإعداد</span>
                    </ExternalLink>
                </div>
                </>
            )
        }
    }
    const renderCompatibleVehicles = () => {
        if (!accessory.compatibleModels || !accessory.compatibleModels.length) return <li>No compatible vehicles listed.</li>;
        return accessory.compatibleModels.map((compatibleModel, index) => <li key={index}>{compatibleModel}</li>);
                                              };

                                              return (
                                              <>
                                              <div>
                                                  <Navbar />
                                                  {!loading && addedToCart && (
                                                      <div className="cart-updated-msg">
                                                          <div className="container">
                                                              <p>
                                                                    
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="#008000">
                                                                <circle cx="16" cy="16" r="14" fill="#008000"/>
                                                                <polyline points="22 10 14 20 10 16" fill="none" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></polyline>
                                                            </svg>

                                                                  <span language="ar">نجحت! تمت إضافة المنتج إلى عربة التسوق</span>
                                                                  <span language="en">Success! Item added to cart</span>
                                                              </p>
                                                          </div>
                                                      </div>
                                                  )}
                                                  <div className="container">
                                                      <div className="row">
                                                          <div className="col-12 col-md-6 d-none d-md-block">
                                                              <div className="breadcrumb">
                                                                  <Link to="/">
                                                                      <span language="ar">الملحقات</span>
                                                                      <span language="en">Accessories</span>
                                                                  </Link>
                                                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                                                                  </svg>
                                                                  <Link to={"/accessory"}>{ category.name }</Link>
                                                              </div>
                                                          </div>
                                                          <div className="col-12 col-md-6">
                                                              <div className="backtores">
                                                                  <Link to={"/accessory"}>
                                                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 0 16 16">
                                                                          <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                                                                      </svg>
                                                                      <span language="ar">العودة إلى النتائج</span>
                                                                      <span language="en">Back to Results</span>
                                                                  </Link>
                                                              </div>
                                                          </div>
                                                      </div>

                                                      {loading && (
                                                          <div className="loader-container">
                                                              <div className="loader"><img src='/images/Rolling@1x-1.0s-200px-200px.gif' width={100} height={100} alt="Loading"/></div>
                                                          </div>
                                                      )}
                                                      {!loading && accessory.is_active !== 1 && (
                                                          <div>
                                                              Accessory Not Found
                                                          </div>
                                                      )}
                                                      {!loading && accessory.is_active === 1 && (
                                                          <div className="details">
                                                              <div className="row">
                                                                  <div className="col-12 col-lg-6">
                                                                      <div className="image-magnifier-container" style={{ position: 'relative', overflow: 'hidden' }}>
                                                                          <img
                                                                              ref={imageRef}
                                                                              src={mainImage}
                                                                              alt={accessory.accessory_name}
                                                                              style={{ width: '100%', height: 'auto' }}
                                                                              onMouseEnter={() => setMagnifierVisible(true)}
                                                                              onMouseLeave={() => setMagnifierVisible(false)}
                                                                              onMouseMove={handleMouseMove}
                                                                              />
                                                                          {magnifierVisible && (
                                                                              <div
                                                                                  style={{
                                                                                      position: 'absolute',
                                                                                          left: `${magnifierPosition.x}%`,
                                                                                              top: `${magnifierPosition.y}%`,
                                                                                                  transform: 'translate(-50%, -50%)',
                                                                                                      width: '300px', // Increased from 150px
                                                                                                          height: '300px', // Increased from 150px
                                                                                                              border: '2px solid white',
                                                                                                                  borderRadius: '50%',
                                                                                                                      pointerEvents: 'none',
                                                                                                                          backgroundImage: `url(${mainImage})`,
                                                                                                                              backgroundPosition: `${magnifierPosition.x}% ${magnifierPosition.y}%`,
                                                                                                                                  backgroundRepeat: 'no-repeat',
                                                                                                                                      backgroundSize: '500%',
                                                                                                                                          zIndex: 1000,
                                                                                  }}
                                                                                  />
                                                                          )}
                                                                      </div>

                                                                  </div>
                                                                  <div className="col-12 col-lg-6">                    
                                                                      <div className="info">
                                                                          <h2>{ accessory.accessory_name }</h2>
                                                                          <h4>
                                                                              <span language="en">Part Number</span>
                                                                              <span language="ar">رقم الجزء</span>
                                                                              : { accessory.part_number }</h4>
                                                                          <div className="price">
                                                                              <p className="heading">
                                                                                  <span language="ar">السعر</span>
                                                                                  <span language="en">Fitted Price</span>
                                                                                  *: 
                                                                                  <span className="value"> { accessory.totalWithTax && formatNumber(accessory.totalWithTax.toFixed(2)) } { accessory.currency }</span>
                                                                              </p>
                                                                          </div>
                                                                          <div className="quantity">
                                                                              <p className="heading">
                                                                                  <span language="ar">الكمية</span>    
                                                                                  <span language="en">Quantity</span>
                                                                              </p>
                                                                              <div className="buttons">
                                                                                  <span className="minus" onClick={handleMinusQuantity}>
                                                                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash" viewBox="0 0 16 16">
                                                                                          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"/>
                                                                                      </svg>
                                                                                  </span>
                                                                                  <span className="value">{quantity}</span>
                                                                                  <span className="plus" onClick={handleAddQuantity}>
                                                                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                                                                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                                                                      </svg>
                                                                                  </span>
                                                                              </div>
                                                                          </div>
                                                                          <div className="ford-btn">
                                                                              <span className="add-item-btn" onClick={handleAddToCart}>
                                                                                  <span language="ar">أضف إلى عربة التسوّق </span>
                                                                                  <span language="en">Add to cart</span>
                                                                              </span>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <p className="desc">{ accessory.description }</p>
                                                              <div className="additional">
                                                                  <p className="heading">
                                                                      <span language="ar">معلومات إضافية:</span>
                                                                      <span language="en">Additional Information</span>
                                                                      :</p>
                                                                  <p>
                                                                      <span language="ar">السيارات المتوافقة</span>
                                                                      <span language="en">Compatible Vehicles</span>
                                                                      :</p>
                                                                  <ul>
                                                                      {renderCompatibleVehicles()}
                                                                  </ul>

                                                                  {renderFitmentGuides()}

                                                                  <p className="note">
                                                                      <span language="ar">*السعر أعلاه يشمل المصنعية وأي ضرائب سارية. الأسعار قابلة للتغيير، يرجى التحقق من الأسعار النهائية ومعلومات الضمان مع الموزع</span>
                                                                      <span language="en">*Price above includes labor and any applicable taxes. Prices are subject to change, please verify final prices with Distributor.</span>
                                                                  </p>
                                                              </div>
                                                          </div>
                                                      )}
                                                  </div>
                                                  <Footer />
                                              </div>
                                              </>
                                             );
    }

    export default Accessory;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';
import LookupForm from '../sections/search/Lookupform';

function Search() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

    useEffect(() => {
        const checkCredentials = () => {
            const country_id = localStorage.getItem('country_id');
            if (!country_id) {
                navigate('/country');
                return false;
            }
            const dealer_id = localStorage.getItem('dealer_id');
            if (!dealer_id) {
                navigate('/dealer');
                return false;
            }
            return true;
        };

        if (checkCredentials()) {
            setIsLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        // Retrieve stored values from localStorage or use location state
        const storedModel = localStorage.getItem('selectedModel');
        const storedYear = localStorage.getItem('selectedYear');
        
        setSelectedModel(storedModel || location.state?.selectedModel || '');
        setSelectedYear(storedYear || location.state?.selectedYear || '');
    }, [location.state]);

    useEffect(() => {
        // Store values in localStorage whenever they change
        if (selectedModel) localStorage.setItem('selectedModel', selectedModel);
        if (selectedYear) localStorage.setItem('selectedYear', selectedYear);
    }, [selectedModel, selectedYear]);

    useEffect(() => {
        // Listen for language changes in localStorage
        const handleLanguageChange = () => {
            setLanguage(localStorage.getItem('language') || 'en');
        };

        window.addEventListener('storage', handleLanguageChange);

        return () => {
            window.removeEventListener('storage', handleLanguageChange);
        };
    }, []);

    if (isLoading) {
        return (
            <div>
                <Navbar />
                <div>
                    {language === 'ar' ? 'جارٍ التحميل...' : 'Loading...'}
                </div>
                <Footer />
            </div>
        );
    }

    return (
        <div>
            <Navbar />
            <LookupForm 
                selectedModel={selectedModel} 
                selectedYear={selectedYear}
                setSelectedModel={setSelectedModel}
                setSelectedYear={setSelectedYear}
                language={language}
            />
            <Footer />
        </div>
    );
}

export default Search;
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Navbar from '../Navbar';
import Footer from '../Footer';
import SearchForm from '../sections/category/SearchForm';

function Category() {  

    const navigate = useNavigate();
    const { id } = useParams();

     useEffect(() => {
        const country_id = localStorage.getItem('country_id');
        if (!country_id) {
            navigate('/country');
        } else {
            const dealer_id = localStorage.getItem('dealer_id');
            if (!dealer_id) {
                navigate('/dealer');
            } else {
                localStorage.setItem('category_id', id);
            }
        }
    }, [navigate, id]);

    return (
        <>
        <div>
            <Navbar />
            <SearchForm />
            <Footer />
        </div>
        </>
    );
}

export default Category;
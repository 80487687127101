import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default class CountryListAr extends React.Component {
    state = {
        countries: [],
        loading: true,
    }

    handleCountryClick = (country_id) => {
        this.setState({loadinng: true});
        localStorage.setItem('language', 'ar');
        localStorage.setItem('country_id', country_id);
        localStorage.removeItem('dealer_id');
        localStorage.removeItem('cart');
        document.documentElement.lang = 'ar';
        document.documentElement.dir = 'rtl';
        
        const existingLink = document.getElementById('bootstrap-stylesheet');
        if (existingLink) {
            existingLink.remove();
        }

        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.id = 'bootstrap-stylesheet';
        link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.rtl.min.css';
        document.head.appendChild(link);
        this.setState({loading: false});
        
    }

   
    componentDidMount() {
        this.setState({loadinng: true});
        let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
        const config = {
            headers: { 'Authorization': token, 'X-Locale': 'ar'}
        };
        axios.get(
            process.env.REACT_APP_FORD_API_URL + '/api/v1/countries/filter?market_id=' + process.env.REACT_APP_MARKET_ID + '&brand_id=' + process.env.REACT_APP_BRAND_ID,
            config
        )
            .then(res => {
            const countries = res.data.data;
            this.setState({ countries });
            this.setState({loading: false});
        }).catch(error => {
            this.setState({loading: false});
        })
    }

    render() {
        
        const { countries, loading } = this.state;
        
        return (
            <>
            {loading &&
                <div className="loader-container">
                    <div className="loader"><img src='../images/Rolling@1x-1.0s-200px-200px.gif' width={100} height={100}/></div>
                </div>
            }
            <ul>
            {!loading && countries.length && 
                    countries.map
                    (
                        country => <li key={country.id}><Link to="/dealer" onClick={() => this.handleCountryClick(country.id)}>{country.name}</Link></li> 
                    )
                }
            </ul>
        </>
        )
    }
}
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import './Arabic.css';


import Home from './components/pages/Home';
import Country from './components/pages/Country';
import Dealer from './components/pages/Dealer';
import Category from './components/pages/Category';
import NewAccessories from './components/pages/NewAccessories';
import Cart from './components/pages/Cart';
import Checkout from './components/pages/Checkout';
import Accessory from './components/pages/Accessory';
import Faq from './components/pages/Faq';
import Search from './components/pages/Search';
import { CartProvider } from './contexts/CartContext';
import AccessoriesDeepLink from './components/pages/AccessoriesDeepLink';


function App() {

    useEffect(() => {
        const language = localStorage.getItem('language') || 'en';

        document.documentElement.lang = 'en';
        document.documentElement.dir = 'ltr';
        if(language === 'ar'){
            document.documentElement.lang = 'ar';
            document.documentElement.dir = 'rtl';
        }

        document.title = process.env.REACT_APP_WHICH_APP + ' Accessories';

        const faviconPath = '/images/'+process.env.REACT_APP_WHICH_APP + '-favicon.ico';
        const faviconLink = document.querySelector("link[rel~='icon']") || document.createElement('link');
        faviconLink.type = 'image/x-icon';
        faviconLink.rel = 'shortcut icon';
        faviconLink.href = faviconPath;
        document.getElementsByTagName('head')[0].appendChild(faviconLink);

        const existingLink = document.getElementById('bootstrap-stylesheet');
        if (existingLink) {
            existingLink.remove();
        }

        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.id = 'bootstrap-stylesheet';
        link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css';
        if(language === 'ar'){
            link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.rtl.min.css';
        }
        document.head.appendChild(link);

        const app_name = process.env.REACT_APP_WHICH_APP;
        if(app_name === 'Lincoln'){
            import('./lincoln.css')
                .catch(err => console.error('Error loading lincoln theme CSS:', err));
        }
    });



    return (
        <CartProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/country" element={<Country />} />
                    <Route path="/dealer" element={<Dealer />} />
                    <Route path="/accessories/:id/:name" element={<Category />} />
                    <Route path="/accessories/new" element={<NewAccessories />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/accessory/:id/:name" element={<Accessory />} />
                    <Route path="/accessory" element={<Search />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/dl/accessories" element={<AccessoriesDeepLink />} />
                </Routes>
            </Router>
        </CartProvider>
    );
}

export default App;
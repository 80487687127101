import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default class DealerList extends React.Component {
    state = {
        dealers: [],
        dealer_id: null,
        loading: true
    }

    handleDealerChange = (event) => {
        this.setState({ dealer_id: event.target.value });
    };

    handleLinkClick = (event) => {
        if (!this.state.dealer_id) {
            event.preventDefault();
            return;
        }
        localStorage.setItem('dealer_id', this.state.dealer_id);
        localStorage.removeItem('cart');
    };

    componentDidMount() {
        this.setState({ loading: true });
        
        // Load the default dealer_id from localStorage
        const defaultDealerId = localStorage.getItem('dealer_id');
        if (defaultDealerId) {
            this.setState({ dealer_id: defaultDealerId });
        }

        let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
        const config = {
            headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
        };
        const country_id = localStorage.getItem('country_id');
        axios.get(
            `${process.env.REACT_APP_FORD_API_URL}/api/v1/dealers/filter?country_id=${country_id}&brand_id=${process.env.REACT_APP_BRAND_ID}&market_id=${process.env.REACT_APP_MARKET_ID}`,
            config
        )
            .then(res => {
                const dealers = res.data.data;
                this.setState({ dealers, loading: false });
            })
            .catch(error => {
                console.error('Error fetching dealers:', error);
                this.setState({ loading: false });
            });
    }

    render() {
        const { dealers, loading, dealer_id } = this.state;
        return (
            <div>
                <div className="accordion" id="dealersAccordion">
                    {loading && dealers.length === 0 &&
                        <div className="loader-container">
                            <div className="loader"><img src='/images/Rolling@1x-1.0s-200px-200px.gif' width={100} height={100} alt="Loading"/></div>
                        </div>
                    }
                    {!loading && dealers.length > 0 &&
                        dealers.map(dealer => (
                            <div className="accordion-item" key={dealer.id}>
                                <h2 className="accordion-header" id={`heading${dealer.id}`}>
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${dealer.id}`} aria-expanded="true" aria-controls={`collapse${dealer.id}`}>
                                        {dealer.trade_name}
                                    </button>
                                </h2>
                                <div id={`collapse${dealer.id}`} className="accordion-collapse collapse" aria-labelledby={`heading${dealer.id}`} data-bs-parent="#dealersAccordion">
                                    <div className="accordion-body">
                                        <div className="row">
                                            {dealer.dealerships.map((dealership) => (
                                                <div className="col-12 col-md-5" key={dealership.id}>
                                                    <div className="form-check">
                                                        <input 
                                                            value={dealership.id} 
                                                            className="form-check-input" 
                                                            type="radio" 
                                                            name="flexRadioDefault" 
                                                            id={`flexRadioDefault${dealership.id}`} 
                                                            onChange={this.handleDealerChange}
                                                            checked={dealer_id === dealership.id.toString()}
                                                        />
                                                        <label className="form-check-label" htmlFor={`flexRadioDefault${dealership.id}`}>
                                                            <span className="name">{dealer.trade_name}</span>
                                                            {dealership.addresses.map((address, key2) => (
                                                                <span className="address" key={key2}>
                                                                    {address.address_1}, {address.address_2} {address.city.name} {address.state.name}
                                                                </span>
                                                            ))}
                                                            <br/>
                                                            {dealership.contacts.slice(0, 1).map((contact, key2) => (
                                                                <span className="contact" key={key2}>
                                                                    {(contact.phone_number || '').trim()}
                                                                </span>
                                                            ))}
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="ford-btn">
                    <Link 
                        to="/" 
                        className={`btn ${!dealer_id ? 'disabled' : ''}`} 
                        onClick={this.handleLinkClick}
                        style={{ pointerEvents: dealer_id ? 'auto' : 'none', opacity: dealer_id ? 1 : 0.5 }}
                    >
                        <span language="ar">الاطلاع على الملحقات </span>
                        <span language="en">Find Accessories </span>
                        <svg language="en" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                        </svg>
                        <svg language="ar" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                        </svg>
                    </Link>
                </div>
            </div>
        )
    }
}
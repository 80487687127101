import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default class Categories extends React.Component {
    state = {
        categories: [],
        loading: true,
    }

    componentDidMount() {
        this.setState({ loading: true });
        const dealer_id = localStorage.getItem('dealer_id');
        let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
        const config = {
            headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en' }
        };
        axios.get(
            process.env.REACT_APP_FORD_API_URL + '/api/v1/categories?dealer_id=' + dealer_id + '&brand_id=' + process.env.REACT_APP_BRAND_ID + '&market_id=' + process.env.REACT_APP_MARKET_ID,
            config
        )
            .then(res => {
                const categories = res.data;
                this.setState({ categories, loading: false });
            })
    }

    slugify(text) {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\p{L}\p{N}\-]+/gu, '')
            .replace(/\-\-+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    }

    handleCategoryClick = (categoryId, categoryName) => {
        localStorage.setItem('selectedCategory', categoryId);
        localStorage.setItem('selectedCategoryName', categoryName);
        if(categoryId == 'new'){
            localStorage.removeItem('selectedModel');
            localStorage.removeItem('selectedYear');
        }
        
        // Navigate to the search page
        window.location.href = '/accessory';
    }

    render() {
        const { categories, loading } = this.state;
        return (
            <div className="accessory-cats" id="accessories">
                <div className="container">
                    <div className="row gx-5">
                        {loading && categories.length === 0 &&
                            <div className="loader-container">
                                <div className="loader"><img src='../images/Rolling@1x-1.0s-200px-200px.gif' width={100} height={100}/></div>
                            </div>
                        }
                        {!loading && categories.length && 
                            categories.map(category =>
                                <div className="col-12 col-md-6 col-lg-4" key={category.id}>
                                    <div className="card">
                                        {category.media.map((media, key2) => (
                                            <img key={key2} src={media.original_url} alt={category.name} />
                                        ))}
                                        <p className="name">{category.name}</p>
                                        <div className="ford-btn">
                                            <Link 
                                                to="#" 
                                                className="btn"
                                                onClick={() => this.handleCategoryClick(category.id, category.name)}
                                            >
                                                <span language="ar">البحث عن الملحقات</span>
                                                <span language="en">Find Accessories</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="card">
                                <img src={`images/new-accessories-${process.env.REACT_APP_WHICH_APP}.jpg`} alt="New Accessories" />
                                <p className="name">
                                    <span language="en">New Accessories</span>
                                    <span language="ar">ملحقات جديدة</span>
                                </p>
                                <div className="ford-btn">
                                    <Link 
                                        to="#" 
                                        className="btn"
                                        onClick={() => this.handleCategoryClick('new', 'New Accessories')}
                                    >
                                        <span language="ar">البحث عن الملحقات</span>
                                        <span language="en">Find Accessories</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import '../sections/country/Country.css';
import Navbar from '../Navbar';
import Footer from '../Footer';
import CountryList from '../sections/country/CountryList';
import CountryListAr from '../sections/country/CountryListAr';

function Country() {

    const [country, setCountry] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const country_id = localStorage.getItem('country_id') || '';
        if(!country_id){
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        const { latitude, longitude } = position.coords;
                        fetchCountry(latitude, longitude);
                    }
                );
            }
        }
    }, []);

    const fetchCountry = async (latitude, longitude) => {
        try {
            // Use a reverse geocoding service to get the country
            const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`);
            const data = await response.json();
            let geo_country = data.countryName;
            if(geo_country){     
                let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
                const config = {
                    headers: { 'Authorization': token}
                };
                axios.get(
                    process.env.REACT_APP_FORD_API_URL + '/api/v1/countries/filter?market_id=' + process.env.REACT_APP_MARKET_ID + '&brand_id=' + process.env.REACT_APP_BRAND_ID,
                    config
                )
                .then(res => {
                    const countries = res.data.data;
                    countries.forEach((country, index) => {
                        if(geo_country === country.name){
                            localStorage.setItem('country_id', country.id);
                            navigate('/dealer');
                        }
                    });
                })
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
        <div>
            <Navbar />
            <div className="page-content page-cs">
                <div className="container">
                    <div className="country-selection">
                        <div className="d-none d-lg-block">
                            <div className="row gx-0">
                                <div className="col-12 col-md-6">
                                    <div className="countries english br">
                                        <h3>Choose Your Country</h3>
                                        <CountryList />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="countries arabic">
                                        <h3>اختر دولتك</h3>
                                        <CountryListAr />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-block d-lg-none mobile">
                            <div className="accordion" id="countryAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            Choose Your Country
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#countryAccordion">
                                        <div className="accordion-body">
                                            <CountryList />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            اختر دولتك
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#countryAccordion">
                                        <div className="accordion-body">
                                            <CountryListAr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </>
    );
}

export default Country;
import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../Navbar';
import Footer from '../Footer';

export default class Faq extends React.Component {

    render() {
        return  (
            <>
            <div>
                <Navbar />
                <div className="faq">
                    <div className="container">

                        <div className="breadcrumb">
                            <Link to="/">Home</Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                            </svg>
                            <Link to="/faq">Frequently Asked Questions</Link>
                        </div>

                    </div>
                    <img className="banner-img" src="../images/faq.png" />
                    <div className="container">
                        <h2>Frequently Asked Questions</h2>

                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        How do I use the "My Ford Accessory" website?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>When first visiting the website, your country and closest list of distributors will be identified based on your location:</p>
                                        <ul>
                                            <li>You will be prompted to select a distributor of your choice</li>
                                            <li>Now that your location is set you will start by selecting the Make/Model/Year to find your vehicle, or search by VIN</li>
                                            <li>You can also search accessories by category on the homepage using the images or selecting from the top navigation</li>
                                            <li>Once you see your search results for the vehicle you selected, you can choose an accessory or filter by Accessory types.</li>
                                            <li>Select an accessory/View Details and you will see detailed information, you can also “Add to Cart”</li>
                                            <li>When you are done shopping for accessories view your Cart at the top of the page, you will have the option to Review Cart and change the quantities, or “Checkout” to complete your request</li>
                                            <li>For Checkout you will be required to enter either an email address, phone number or both for a distributor to contact you. At the bottom of the page click “Send Order to Distributor”</li>
                                            <li>Print a PDF of your request for your records and a distributor will contact you shortly to help you purchase your accessories</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        How is my location identified and can I change it?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Your “geolocation” is your real-time location identified by the device you are using to access the website. Yes, you can easily change the country of your choice. Click on the country symbol at the top of the page and select from the list of available countries. You will then be prompted to select a distributor of your choice.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Can I buy accessories directly from Ford Motor Company?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>This website is brought to you by Ford Motor Company together with the distributor you select. Ford Motor Company is not the seller of the accessories offered on this website. Rather, any accessories requested through this website will be sold to you by your Ford distributor.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        What if I have a question about the accessories I sent to my Distributor?
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>For questions regarding the accessories you inquired about, you will need to contact the distributor you selected.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        How can I find the warranty information for the accessories I'm interested in?
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>All GFA/FLA (Genuine Ford/Ford Licensed) accessories installed prior to vehicle delivery are covered by Ford’s new vehicle limited warranty. If purchased as over the counter or after the vehicle sale, service parts warranty applies (12months/20,000 kms, which occurs first). Please contact your Ford Distributor, for any clarity on warranty information on specific accessories.</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <Footer />
            </div>
            </>
        )
    }
}

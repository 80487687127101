import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';

import Navbar from '../Navbar';
import Footer from '../Footer';
import { CartContext } from '../../contexts/CartContext';

const Checkout = () => {
    const { cart, clearCart } = useContext(CartContext);
    const navigate = useNavigate();

    const [dealer, setDealer] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [customerCommunication, setCustomerCommunication] = useState(false);
    const [taxes, setTaxes] = useState([]);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPhone, setIsValidPhone] = useState(true);
    const [orderSubmittedSuccessfully, setOrderSubmittedSuccessfully] = useState(false);
    const [currency, setCurrency] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkCredentials = () => {
            const country_id = localStorage.getItem('country_id');
            if (!country_id) {
                navigate('/country');
                return false;
            }
            const dealer_id = localStorage.getItem('dealer_id');
            if (!dealer_id) {
                navigate('/dealer');
                return false;
            }
            return true;
        };

        if (checkCredentials()) {
            loadCheckoutData();
        }
    }, [navigate]);

    const loadCheckoutData = async () => {
        setIsLoading(true);
        setOrderSubmittedSuccessfully(false);
        const dealer_id = localStorage.getItem('dealer_id');

        let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
        const config = {
            headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
        };

        try {
            const cartResponse = await Promise.all(
                Object.entries(cart).map(([key, value]) => 
                                         axios.get(`${process.env.REACT_APP_FORD_API_URL}/api/v1/accessories/${key}?dealer_id=${dealer_id}`, config)
                                         .then(res => ({
                    id: value.id,
                    quantity: value.quantity,
                    accessory: res.data,
                    category_id: value.category_id
                }))
                                        )
            );

            setCartItems(cartResponse);
            if (cartResponse.length > 0) {
                setCurrency(cartResponse[0].accessory.currency);
            }

            const dealerResponse = await axios.get(
                `${process.env.REACT_APP_FORD_API_URL}/api/v1/dealerships/${dealer_id}`,
                config
            );
            setDealer(dealerResponse.data);

            const taxesResponse = await axios.get(
                `${process.env.REACT_APP_FORD_API_URL}/api/v1/taxes/filter?dealer_id=${dealer_id}`,
                {
                    params: { dealer_id: dealer_id },
                    ...config
                }
            );
            setTaxes(taxesResponse.data.data);

        } catch (error) {
            console.error('Error loading checkout data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSendOrder = () => {
        setOrderSubmittedSuccessfully(false);
        let validationPassed = true;

        if (customerEmail === '') {
            alert('Please enter your email address.');
            validationPassed = false;
        }

        if (validationPassed && customerPhone === '') {
            alert('Please enter your phone number.');
            validationPassed = false;
        }

        if (validationPassed && !validateEmail(customerEmail)) {
            alert('Please enter valid email address.');
            validationPassed = false;
        }

        //        if (validationPassed && !customerCommunication) {
        //            alert('Please check communication agreement.');
        //            validationPassed = false;
        //        }

        if (validationPassed) {
            let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
            const config = {
                headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
            };
            const dealer_id = localStorage.getItem('dealer_id');

            axios.post(
                `${process.env.REACT_APP_FORD_API_URL}/api/v1/customers`,
                {
                    email: customerEmail,
                    phone: customerPhone,
                    dealer_id: dealer_id
                },
                config
            )
                .then(res => {
                const customer = res.data;
                setCustomer(customer);

                let customer_id = customer.id;

                axios.post(
                    `${process.env.REACT_APP_FORD_API_URL}/api/v1/customers/${customer_id}/cart`,
                    {
                        customer_id: customer_id
                    },
                    config
                )
                    .then(res => {
                    const cart = res.data;

                    Promise.all(cartItems.map(cartItem => 
                                              axios.post(
                        `${process.env.REACT_APP_FORD_API_URL}/api/v1/carts/${cart.id}/items`,
                        {
                            accessory_id: cartItem.accessory.id,
                            quantity: cartItem.quantity,
                            cart_id: cart.id,
                            dealer_id: dealer_id,
                            category_id: cartItem.category_id
                        },
                        config
                    )
                                             ))
                        .then(() => {
                        axios.post(
                            `${process.env.REACT_APP_FORD_API_URL}/api/v1/orders`,
                            {
                                customer_id: customer_id,
                                dealer_id: dealer_id,
                                cart_id: cart.id,
                                brand_id: process.env.REACT_APP_BRAND_ID
                            },
                            config
                        )
                            .then(() => {
                            clearCart();
                            localStorage.setItem('cart', JSON.stringify({}));
                            setOrderSubmittedSuccessfully(true);
                        });
                    });
                });
            });
        }
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validatePhone = (number) => {
        const regex = /^[0-9]{10,15}$/;
        return regex.test(number);
    };

    const handleEmailChange = (event) => {
        const email = event.target.value;
        setCustomerEmail(email);
        setIsValidEmail(validateEmail(email));
    };

    const handlePhoneChange = (event) => {
        const phone = event.target.value;
        setCustomerPhone(phone);
        setIsValidPhone(validatePhone(phone));
    };

    const handleCommunicationChange = (event) => {
        setCustomerCommunication(event.target.checked);
    };

    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const formatTaxValue = (tax) => {
        if (tax.type === 1) {
            return `${tax.tax.name}: ${tax.rate}%`;
        } else {
            return `${tax.name}: ${tax.rate}`;
        }
    };

    const calculateSubtotal = () => {
        let subtotal = cartItems.reduce((total, item) => total + (item.accessory.totalBeforeTax * item.quantity), 0);
        return subtotal.toFixed(2);
    };

    const calculateTotal = () => {
        let total = cartItems.reduce((total, item) => total + (item.accessory.totalWithTax * item.quantity), 0);
        return total.toFixed(2);
    };

    const calculateTax = () => {
        let tax = cartItems.reduce((total, item) => total + (item.accessory.taxesTotal * item.quantity), 0);
        return tax.toFixed(2);
    };

    if (isLoading) {
        return (
            <>
            <Navbar />
            <div className="loader-container">
                <div className="loader"><img src='/images/Rolling@1x-1.0s-200px-200px.gif' width={100} height={100} alt="Loading"/></div>
            </div>
            <Footer />
            </>
        );
    }

    let email_placeholder = 'Email address';
    let phone_placeholder = 'Phone number';
    let hasHeroImage = false;
    const language = localStorage.getItem('language') || 'en';
    if (language === 'ar') {
        email_placeholder = 'عنوان البريد الإلكتروني';
        phone_placeholder = 'رقم الهاتف';
    }

    return (
        <>
        <div>
            <Navbar />

            {cartItems.length === 0 && (
                <div className="country-change-msg">
                    <div className="container">
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                            </svg>
                            <span language="ar">لديك أي سلع في عربة التسوق.</span>
                            <span language="en">You have no items in your cart.</span>                            
                        </p>
                    </div>
                </div>
            )}

            {orderSubmittedSuccessfully && (
                <div className="order-submitted-msg">
                    <div className="container">
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="#008000">
                                <circle cx="16" cy="16" r="14" fill="#008000"/>
                                <polyline points="22 10 14 20 10 16" fill="none" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></polyline>
                            </svg>
                            <span language="ar">نجاح! تم إرسال الطلب بنجاح</span>
                            <span language="en">Success! Order sent successfully</span>
                        </p>
                    </div>
                </div>
            )}

            <div className="container">
                <div className="checkout">
                    <div className="breadcrumb">
                        <Link to="/cart">
                            <span language="ar">عربة التسوق</span>
                            <span language="en">Cart</span>
                        </Link>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                        </svg>
                        <HashLink to="#">
                            <span language="ar">السداد مع الخروج</span>
                            <span language="en">Checkout</span>
                        </HashLink>
                    </div>

                    <h3>
                        <span language="ar">السداد مع الخروج</span>
                        <span language="en">Checkout</span>
                    </h3>

                    <div className="distributor">
                        <p className="heading">
                            <span language="ar">معلومات الموزع</span>
                            <span language="en">Distributor Information</span>
                        </p>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <p>
                                    {dealer.dealer && dealer.dealer.trade_name}<br/>
                                    {dealer.addresses && dealer.addresses[0].address_1} <br/>
                                    {dealer.addresses && dealer.addresses[0].address_2} <br/>
                                    {dealer.addresses && dealer.addresses[0].city.name} {dealer.addresses && dealer.addresses[0].state.name} <br/>
                                    
                                    {dealer.contacts && dealer.contacts.slice(0, 1).map((contact, index) => (
                                                                <span key={index}>{contact.phone_number} </span>
                                                            ))}

                                </p>
                            </div>
                            <div className="col-12 col-md-4">
                                <p>
                                    <span language="ar">ساعات العمل:</span>
                                    <span language="en">Hours Of Operation</span>
                                    :<br/>
                                    <span language="ar">الأحد</span>
                                    <span language="en">Sundays</span>
                                    : {dealer.operating_hours_sundays}<br/>
                                    <span language="ar">أيام الأسبوع</span>
                                    <span language="en">Weekdays</span>
                                    : {dealer.operating_hours_weekdays}<br/>
                                    <span language="ar">السبت</span>
                                    <span language="en">Saturdays</span>
                                    : {dealer.operating_hours_saturdays}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="customer">
                        <p className="heading">
                            <span language="ar">معلومات العميل</span>
                            <span language="en">Customer Information</span>
                        </p>
                        <p language="en">Please enter your contact information so we may process your cart and put you in contact with a distributor representative.</p>
                        <p language="ar">يرجى إدخال معلومات الاتصال الخاصة بك لكي نتمكن من معالجة عربة التسوق الخاصة بك ونجعلك على اتصال بممثل لدى الموزع.</p>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <form>
                                    <input 
                                        required 
                                        type="email" 
                                        className="form-control" 
                                        id="customerEmail" 
                                        placeholder={email_placeholder}  
                                        onChange={handleEmailChange} 
                                        style={{ borderColor: isValidEmail ? 'initial' : 'red' }} 
                                        />
                                    {!isValidEmail && <p language="en" style={{ color: 'red' }}>Incorrect Email Address</p>}
                                    {!isValidEmail && <p language="ar" style={{ color: 'red' }}>عنوان بريد إلكتروني غير صحيح</p>}
                                    <input 
                                        required 
                                        className="form-control" 
                                        type="text" 
                                        placeholder={phone_placeholder} 
                                        aria-label="Phone Number" 
                                        pattern="^\d|\s|\.|\+$" 
                                        minLength="10" 
                                        maxLength="15" 
                                        onChange={handlePhoneChange}
                                        style={{ borderColor: isValidPhone ? 'initial' : 'red' }} 
                                        />
                                    {!isValidPhone && <p language="en" style={{ color: 'red' }}>Incorrect Phone Number Format. Must be a Minimum of 10 characters.</p>}
                                    {!isValidPhone && <p language="ar" style={{ color: 'red' }}>.تنسيق رقم هاتف غير صحيح .الحد الأدنى لعدد الرموز لا يجوز أن يقل عن 10</p>}
                                    <div className="form-check">
                                        <input                                                  
                                            className="form-check-input" 
                                            type="checkbox" 
                                            id="communication"  
                                            onChange={handleCommunicationChange}
                                            />
                                        <label className="form-check-label" htmlFor="communication">
                                            <span language="ar">الرجاء الاحتفاظ بمعلوماتي في ملف وإرسال مراسلات عبر البريد الإلكتروني بخصوص برنامج ملحقات فورد بما في ذلك معلومات المنتج والعروض والحوافز.</span>
                                            <span language="en">Please keep my information on file and send me email communications regarding the Ford Accessories program including product information, offers and incentives.</span>
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="order">
                        <p className="heading">
                            <span language="ar">تفاصيل الطلب</span>
                            <span language="en">Order Details</span>
                        </p>
                        <div className="products">
                            {cartItems.map((product, index) => (
                                <div className="product" key={index}>
                                    <div className="row">
                                        <div className="col-4 col-md-2">
                                            {product.accessory.media && product.accessory.media.map((media, mediaIndex) => {
                                                if (media.collection_name === "print-image") {
                                                    hasHeroImage = true;
                                                    return <img key={mediaIndex} src={media.original_url} alt="Product" />;
                                                }
                                                return null;
                                            })}
                                            {!hasHeroImage && <img src="../images/default.jpg" alt="Default" />}
                                            {hasHeroImage = false}
                                        </div>
                                        <div className="col-8 col-md-10">
                                            <p className="name">{product.accessory.accessory_name}</p>
                                            <p className="partno">
                                                <span language="ar">رقم الجزء</span>
                                                <span language="en">Part Number</span>
                                                : {product.accessory.part_number}</p>
                                            <p>
                                                <span className="price">{formatNumber(product.accessory.totalBeforeTax.toFixed(2))} {product.accessory.currency}</span>
                                                <span className="quantity">
                                                    <span language="ar">الكمية</span>
                                                    <span language="en">Qty</span>
                                                    : {product.quantity}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="details">
                            <p>
                                <span className="label" language="ar">المجموع الفرعي</span>
                                <span className="label" language="en">Subtotal</span>
                                <span className="value"> {formatNumber(calculateSubtotal())} {currency}</span>
                            </p>
                            <div>
                                {taxes && taxes.map((tax, index) => (
                                    <p key={index}>
                                        <span className="label">{tax.tax.name}</span>
                                        {/* <span className="value">{tax.type === 1 ? tax.rate+`%` : tax.rate} {currency}</span> */}
                                        <span className="value">{formatNumber(calculateTax())} { currency }</span>

                                    </p>
                                ))}
                            </div>
                            <p className="total">
                                <span className="label" language="ar">الإجمالي</span>
                                <span className="label" language="en">Total</span>
                                <span className="value"> {formatNumber(calculateTotal())} {currency}</span>
                            </p>
                        </div>
                    </div>

                    {cartItems.length > 0 && (
                        <div className="ford-btn">
                            <HashLink to="#" className="btn" onClick={handleSendOrder}>
                                <span language="ar">إرسال طلب إلى الموزع</span>
                                <span language="en">Send Order to Distributor</span>                            
                            </HashLink>
                        </div>
                    )}

                    <p className="note">*
                        <span language="ar">مواصفات المنتج وتوفّره صحيحان في وقت النشر. كانت المواصفات والسعر صحيحين في وقت إعداد الكتيّب ونشره. تحتفظ شركة فورد موتور كومباني بحقها في تغيير المواصفات في أي وقت دون أيّ التزامات عليها. إن الإكسسوارات المعروضة قد لا تكون متوفرة في المخزون وقت الطلب ولكن بالإمكان طلبها من منافذ بيع قطعالموزع الغيار ومراكز الصيانة.</span>
                        <span language="en">The product specification and it's availability is correct at any time of publication. Specifications and pricing were correct at the time the material was being prepared and published. Ford Motor Company reserves the right to change specifications at any time without incurring obligation. Accessories listed are subject to stock availability and can be ordered from Distributor service & parts facilities.</span>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
        </>
    );
};

export default Checkout;
import React from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

export default class Mainform extends React.Component {

    state = {
        models: [],
        years: [],
        selectedModel: '',
        selectedYear: '',
        language: localStorage.getItem('language') || 'en',
        navigate: false
    }    
    


    handleSearchClick = (event) => {
        event.preventDefault();
        
        if (this.state.selectedModel) {
            localStorage.setItem('selectedModel', this.state.selectedModel);
        } else {
            localStorage.removeItem('selectedModel');
        }
        
        if (this.state.selectedYear) {
            localStorage.setItem('selectedYear', this.state.selectedYear);
        } else {
            localStorage.removeItem('selectedYear');
        }
        
        // Always remove category when submitting from homepage
        localStorage.removeItem('category_id');
        
        this.setState({ navigate: true });
    }

    handleModelChange = (event) => {
        let model_id = event.target.value;
        const dealer_id = localStorage.getItem('dealer_id');
        this.setState({ selectedModel: model_id });
        localStorage.setItem('selectedModel', model_id);
        
        let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
        const config = {
            headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
        };
        axios.get(
            process.env.REACT_APP_FORD_API_URL + '/api/v1/car-models/' + model_id + '?dealer_id=' + dealer_id + '&brand_id=' + process.env.REACT_APP_BRAND_ID + '&market_id=' + process.env.REACT_APP_MARKET_ID,
            config
        )
            .then(res => {
            const years = res.data.years;
            this.setState({ years });
        })
    };

    handleYearChange = (event) => {
        let year_id = event.target.value;
        this.setState({ selectedYear: year_id });
        localStorage.setItem('selectedYear', year_id);
    };

    componentDidMount() {
        const dealer_id = localStorage.getItem('dealer_id');
        let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
        const config = {
            headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
        };
        axios.get(
            process.env.REACT_APP_FORD_API_URL + '/api/v1/car-models/filter?brand_id=' + process.env.REACT_APP_BRAND_ID + '&dealer_id=' + dealer_id + '&market_id=' + process.env.REACT_APP_MARKET_ID,
            config
        )
            .then(res => {
            const models = res.data.data;
            this.setState({ models });
        })
    }

    render() {
        const { models, years, language } = this.state;
        
        if (this.state.navigate) {
            const params = {
              selectedModel: this.state.selectedModel,
              selectedYear: this.state.selectedYear
            };
            return <Navigate to="/accessory" state={params} />;
        }
        
        return (
            <div className="container">
                <div className="main-form">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-0 offset-md-2">
                            <h4>
                                <span language="ar">ما نوع سيارتك؟</span>    
                                <span language="en">What's Your Ride?</span>
                            </h4>
                            <p>
                                <span language="ar">اختر سيارتك لاستكشاف مجموعة الملحقات لدينا</span>
                                <span language="en">Select your vehicle to explore our range of accessories.</span>
                            </p>

                            <form>
                                <div className="row">
                                    <div className="col-12 col-md-4 offset-md-1">
                                        <select className="form-select" aria-label="" id="modelsList" onChange={this.handleModelChange}>
                                            <option disabled selected>
                                                {language === 'en' && 'Model'}
                                                {language === 'ar' && 'الطراز'}
                                            </option>
                                            {models.map((model, key) => (
                                                <option value={model.id}>{model.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <select className="form-select" aria-label="" onChange={this.handleYearChange}>
                                            <option disabled selected>
                                                {language === 'en' && 'Year'}
                                                {language === 'ar' && 'السنة'}
                                                </option>
                                            {years.map((year, key) => (
                                                <option value={year.id}>{year.year}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-3 text-start">
                                        <button type="submit" className="btn ford-btn" disabled={years.length === 0} onClick={this.handleSearchClick}>
                                            <span language="ar">ابحث الآن</span>
                                            <span language="en">Search Now</span>
                                        </button>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
    }
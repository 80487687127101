import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { HashLink } from 'react-router-hash-link';

export default class SearchForm extends React.Component {

    state = {
        accessories: [],
        categories: [],
        models: [],
        years: [],
        selectedModelName: '',
        selectedModelImage: '',
        current_page: 1,
        next_page: false,
        formModel: '',
        formYear: 'all',
        formCategory: 'all',
        isNewCategoryDisabled: false
    }

componentDidMount() {
        const selectedModel = localStorage.getItem('selectedModel') || 'all';
        const selectedYear = localStorage.getItem('selectedYear') || 'all';
        const selectedCategory = localStorage.getItem('selectedCategory') || 'all';

        this.setState({
            formModel: selectedModel,
            formYear: selectedYear,
            formCategory: selectedCategory
        }, () => {
            this.fetchInitialData();
        });
    }

fetchInitialData = () => {
        const { formModel, formYear, formCategory } = this.state;

        if (formModel && formModel !== 'all') {
            this.fetchYears(formModel);
            this.fetchModelDetails(formModel);
        } else {
            this.setState({ years: [] });
        }

        let filter = '';
        if(formModel && formModel !== 'all') filter += '&model_id=' + formModel;
        if(formYear && formYear !== 'all') filter += '&year_id=' + formYear;
        if(formCategory && (formCategory !== 'all' && formCategory !== 'new')) filter += '&category_id=' + formCategory;
        if(formCategory && formCategory === 'new') filter += '&per_page=3&order_by=updated_at&sorder_by_dir=desc&limit=3';

        this.fetchAccessories(filter);
        this.fetchModels();
        this.fetchCategories();
    }

fetchYears = (modelId) => {
    const dealer_id = localStorage.getItem('dealer_id');
    let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
    const config = {
        headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
    };

    axios.get(
        `${process.env.REACT_APP_FORD_API_URL}/api/v1/car-models/${modelId}?dealer_id=${dealer_id}&brand_id=${process.env.REACT_APP_BRAND_ID}&market_id=${process.env.REACT_APP_MARKET_ID}`,
        config
    ).then(res => {
        this.setState({ years: res.data.years });
    });
}

fetchModelDetails = (modelId) => {
    let language = localStorage.getItem('language') || 'en';
    if (modelId === 'all') {
        this.setState({ 
            selectedModelName: 'All Models',
            selectedModelImage: ''
        });
        return;
    }

    const dealer_id = localStorage.getItem('dealer_id');
    let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
    const config = {
        headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
    };

    axios.get(
        `${process.env.REACT_APP_FORD_API_URL}/api/v1/car-models/${modelId}?dealer_id=${dealer_id}&brand_id=${process.env.REACT_APP_BRAND_ID}&market_id=${process.env.REACT_APP_MARKET_ID}`,
        config
    ).then(res => {
        this.setState({ 
            selectedModelName: res.data.name,
            selectedModelImage: res.data.media[0].original_url
        });
    });
}

fetchAccessories = (filter) => {
    const dealer_id = localStorage.getItem('dealer_id');
    let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
    const config = {
        headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
    };

    axios.get(
        `${process.env.REACT_APP_FORD_API_URL}/api/v1/accessories/filter?dealer_id=${dealer_id}&per_page=12${filter}&brand_id=${process.env.REACT_APP_BRAND_ID}&market_id=${process.env.REACT_APP_MARKET_ID}`,
        config
    ).then(res => {
        this.setState({ 
            accessories: res.data.data,
            next_page: !!res.data.next_page_url
        });
    });
}

fetchModels = () => {
    const dealer_id = localStorage.getItem('dealer_id');
    let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
    const config = {
        headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
    };

    axios.get(
        `${process.env.REACT_APP_FORD_API_URL}/api/v1/car-models/filter?brand_id=${process.env.REACT_APP_BRAND_ID}&dealer_id=${dealer_id}&market_id=${process.env.REACT_APP_MARKET_ID}`,
        config
    ).then(res => {
        this.setState({ models: res.data.data });
    });
}

fetchCategories = () => {
    const dealer_id = localStorage.getItem('dealer_id');
    let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
    const config = {
        headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
    };

    axios.get(
        `${process.env.REACT_APP_FORD_API_URL}/api/v1/categories?dealer_id=${dealer_id}&brand_id=${process.env.REACT_APP_BRAND_ID}&market_id=${process.env.REACT_APP_MARKET_ID}`,
        config
    ).then(res => {
        this.setState({ categories: res.data });
    });
}

handleModelChange = (event) => {
    const selectedModel = event.target.value;
    localStorage.setItem('selectedModel', selectedModel);
    this.setState({ 
        formModel: selectedModel, 
        formYear: 'all',  // Reset year to 'all' when model changes
//        formCategory: 'all',  // Reset category to 'all' when model changes
        years: [],
        isNewCategoryDisabled: selectedModel !== 'all'
    }, () => {
        if (selectedModel && selectedModel !== 'all') {
            this.fetchYears(selectedModel);
        }
        this.submitForm();
    });
    
    if(selectedModel != 'all'){
        console.log(selectedModel);
        let selectedCategory = localStorage.getItem('selectedCategory');
        console.log(selectedCategory);
        if(selectedCategory == 'new'){
            localStorage.setItem('selectedCategory', 'all');
            localStorage.setItem('selectedCategoryName', 'All Categories');
            this.setState({ formCategory: 'all' }, this.submitForm);
        }
    }

    // Clear year and category from localStorage when model changes
    localStorage.removeItem('selectedYear');
//    localStorage.removeItem('category_id');
};

handleYearChange = (event) => {
    const selectedYear = event.target.value;
    localStorage.setItem('selectedYear', selectedYear);
    this.setState({ formYear: selectedYear }, this.submitForm);
};

handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    let selectedCategoryName = 'All Categories';

    if (selectedCategory !== 'all') {
        // Find the selected option to get its text content (category name)
        const selectedOption = event.target.options[event.target.selectedIndex];
        selectedCategoryName = selectedOption.textContent;
    }

    // Store category ID and name in localStorage
    localStorage.setItem('selectedCategory', selectedCategory);
    localStorage.setItem('selectedCategoryName', selectedCategoryName);

    this.setState({ formCategory: selectedCategory }, this.submitForm);
};

submitForm = () => {
    const { formModel, formYear, formCategory } = this.state;
    let filter = '';
    if(formModel && formModel !== 'all') filter += '&model_id=' + formModel;
    if(formYear && formYear !== 'all') filter += '&year_id=' + formYear;
    if(formCategory && (formCategory !== 'all' && formCategory !== 'new')) filter += '&category_id=' + formCategory;
    if(formCategory && formCategory === 'new') filter += '&per_page=3&order_by=updated_at&sorder_by_dir=desc&limit=3';

    this.setState({ current_page: 1 });
    this.fetchAccessories(filter);
    this.fetchModelDetails(formModel);
};

handlePreviousClick = (event) => {
    let current_page = this.state.current_page - 1;
    this.setState({ current_page });
    this.fetchPagedAccessories(current_page);
}

handleNextClick = (event) => {
    let current_page = this.state.current_page + 1;
    this.setState({ current_page });
    this.fetchPagedAccessories(current_page);
}

fetchPagedAccessories = (page) => {
    const { formModel, formYear, formCategory } = this.state;
    const dealer_id = localStorage.getItem('dealer_id');
    let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
    const config = {
        headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
    };

    let filter = '';
    if(formModel && formModel !== 'all') filter += '&model_id=' + formModel;
    if(formYear && formYear !== 'all') filter += '&year_id=' + formYear;
    if(formCategory && (formCategory !== 'all' && formCategory !== 'new')) filter += '&category_id=' + formCategory;
    if(formCategory && formCategory === 'new') filter += '&per_page=3&order_by=updated_at&sorder_by_dir=desc&limit=3';
    filter += '&page=' + page;

    axios.get(
        `${process.env.REACT_APP_FORD_API_URL}/api/v1/accessories/filter?dealer_id=${dealer_id}&per_page=12${filter}&brand_id=${process.env.REACT_APP_BRAND_ID}&market_id=${process.env.REACT_APP_MARKET_ID}`,
        config
    ).then(res => {
        this.setState({ 
            accessories: res.data.data,
            next_page: !!res.data.next_page_url
        });
    });
}

slugify(text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\p{L}\p{N}\-]+/gu, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}

renderHeading = () => {
        const selectedCategory = localStorage.getItem('selectedCategory');
        const selectedCategoryName = localStorage.getItem('selectedCategoryName');
        const { selectedModelName, selectedModelImage } = this.state;

        if ((!this.state.formModel || this.state.formModel === 'all') && selectedCategory && selectedCategory !== 'all') {
            return <span>{selectedCategoryName}</span>;
        } else {
            return (
                <>
                    <span language="ar">ملحقات من أجل</span>
                    <span language="en">Accessories for </span>
                    {selectedModelName}
                    {selectedModelName !== 'All Models' && selectedModelImage && (
                        <img 
                            className="m-2" 
                            style={{ width: '100px', height: 'auto' }} 
                            src={selectedModelImage} 
                            alt={selectedModelName} 
                        />
                    )}
                </>
            );
        }
    }

render() {
    const { accessories, categories, models, years, current_page, next_page, selectedModelName, selectedModelImage, formModel, formYear, formCategory, isNewCategoryDisabled } = this.state;
    let hasHeroImage = false;
    let language = localStorage.getItem('language') || 'en';

    return (
        <div className="container">
            <div className="d-block">
                <div className="filter-form">
                    <form>
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-3 col-xl-3">
                                <select className="form-select" aria-label="" onChange={this.handleModelChange} value={formModel}>
                                    <option value='all'>
                                        {language === 'en' ? 'All Models' : 'كل الموديلات'}
                                    </option>
                                    {models.map((model, key) => (
                                        <option key={key} value={model.id}>{model.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-12 col-md-4 col-lg-3 col-xl-3">
                                <select className="form-select" aria-label="" onChange={this.handleYearChange} value={formYear} disabled={!formModel || formModel === 'all'}>
                                    <option value='all'>
                                        {language === 'en' ? 'All Years' : 'كل السنوات'}
                                    </option>
                                    {years.map((year, key) => (
                                        <option key={key} value={year.id}>{year.year}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-12 col-md-4 col-lg-3 col-xl-3">
                                <select 
                                    className="form-select" 
                                    aria-label="" 
                                    onChange={this.handleCategoryChange} 
                                    value={this.state.formCategory}
                                >
                                    <option value='all'>
                                        {language === 'en' ? 'All Categories' : 'كل الفئات'}
                                    </option>
                                    {categories.map((category, key) => (
                                        <option key={key} value={category.id}>{category.name}</option>
                                    ))}
                                    <option value='new' disabled={isNewCategoryDisabled}>
                                        {language === 'en' ? 'New Accessories' : 'ملحقات جديدة'}
                                    </option>
                                </select>
                            </div>
                        </div>                
                    </form>
                </div>
            </div>

            <div className="accessories">
                <h2>{this.renderHeading()}</h2>
                <div className="row gx-5">
                    {accessories && accessories.map((accessory, key) => (
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="accessory">
                                <Link to={"/accessory/" +accessory.id+"/"+this.slugify(accessory.accessory_name)}>
                                    {accessory.media && accessory.media.map((media, index) => {
                                        if (media.collection_name === "print-image") {
                                            hasHeroImage = true;
                                            return <img src={media.original_url} alt={accessory.accessory_name}/>;
                                        }
                                        return null;
                                    })}
                                    { !hasHeroImage && <img src="../images/default.jpg" alt={accessory.accessory_name}/>}
                                    {hasHeroImage = false}
                                </Link>
                                <p className="name">
                                    <Link to={"/accessory/" +accessory.id+"/"+this.slugify(accessory.accessory_name)}>{accessory.accessory_name}</Link>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            { formCategory !== 'new' &&
            <div className="pagination">
                <div className="links">
                    { current_page > 1 && 
                        <span className="prev">
                            <HashLink to="#" onClick={this.handlePreviousClick}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                                    <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                                </svg>
                                <span language="ar">سابق</span>
                                <span language="en">Previous</span>
                            </HashLink>    
                        </span>
                    }
                    <span className="current">
                        {current_page}
                    </span>
                    { next_page && 
                        <span className="next">
                            <HashLink to="#" onClick={this.handleNextClick}>
                                <span language="ar">التالي</span>
                                <span language="en">Next</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"/>
                                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"/>
                                </svg>
                            </HashLink>
                        </span>
                    }
                </div>
            </div>
                }

        </div>
    )
}
}
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';

function SearchForm() {
    const [accessories, setAccessories] = useState([]);
    const [category, setCategory] = useState({});
    const [models, setModels] = useState([]);
    const [years, setYears] = useState([]);
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [nextPage, setNextPage] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        fetchData();
    }, [id]);

    const fetchData = async (page = 1) => {
        setLoading(true);
        const dealer_id = localStorage.getItem('dealer_id');
        let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
        const config = {
            headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
        };

        let modelFilter = selectedModel ? `&model_id=${selectedModel}` : '';
        if (selectedYear) {
            modelFilter += `&year_id=${selectedYear}`;
        }

        try {
            const [categoryRes, accessoriesRes, modelsRes] = await Promise.all([
                axios.get(`${process.env.REACT_APP_FORD_API_URL}/api/v1/categories/${id}?dealer_id=${dealer_id}&brand_id=${process.env.REACT_APP_BRAND_ID}&market_id=${process.env.REACT_APP_MARKET_ID}`, config),
                axios.get(`${process.env.REACT_APP_FORD_API_URL}/api/v1/accessories/filter?category_id=${id}&dealer_id=${dealer_id}&per_page=12&page=${page}${modelFilter}&brand_id=${process.env.REACT_APP_BRAND_ID}&market_id=${process.env.REACT_APP_MARKET_ID}`, config),
                axios.get(`${process.env.REACT_APP_FORD_API_URL}/api/v1/car-models/filter?brand_id=${process.env.REACT_APP_BRAND_ID}&market_id=${process.env.REACT_APP_MARKET_ID}`, config)
            ]);

            setCategory(categoryRes.data);
            setAccessories(accessoriesRes.data.data);
            setModels(modelsRes.data.data);
            setCurrentPage(accessoriesRes.data.current_page);
            setNextPage(!!accessoriesRes.data.next_page_url);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchClick = async (event) => {
        event.preventDefault();
        fetchData(1); // Reset to first page when searching
    };

    const handleModelChange = async (event) => {
        const model = event.target.value;
        setSelectedModel(model);
        setLoading(true);
        localStorage.setItem('selectedModel', model);

        const dealer_id = localStorage.getItem('dealer_id');
        let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
        const config = {
            headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en'}
        };

        try {
            const res = await axios.get(
                `${process.env.REACT_APP_FORD_API_URL}/api/v1/car-models/${model}?dealer_id=${dealer_id}&brand_id=${process.env.REACT_APP_BRAND_ID}&market_id=${process.env.REACT_APP_MARKET_ID}`,
                config
            );
            setYears(res.data.years);
        } catch (error) {
            console.error('Error fetching years:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
        console.log(event.target.value)
        localStorage.setItem('selectedYear', event.target.value);
    };

    const handlePreviousClick = () => {
        if (currentPage > 1) {
            fetchData(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (nextPage) {
            fetchData(currentPage + 1);
        }
    };

    const slugify = (text) => {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\p{L}\p{N}\-]+/gu, '')
            .replace(/\-\-+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    };

    let hasHeroImage = false;
    let language = localStorage.getItem('language') || 'en';

    return (
        <div className="container">
            <div className="d-none d-md-block">
                <div className="filter-form">
                    <form onSubmit={handleSearchClick}>
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-3 col-xl-3">
                                <p className="heading">
                                    <span language="ar">نموذج / بحث السنة</span>
                                    <span language="en">Model / Year Lookup</span>
                                </p>
                            </div>
                            <div className="col-12 col-md-4 col-lg-3 col-xl-3">
                                <select className="form-select" aria-label="" onChange={handleModelChange}>
                                    <option disabled selected>
                                        {language === 'en' ? 'Model' : 'الطراز'}
                                    </option>
                                    {models.map((model) => (
                                        <option key={model.id} value={model.id}>{model.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-12 col-md-4 col-lg-3 col-xl-3">
                                <select className="form-select" aria-label="" onChange={handleYearChange}>
                                    <option disabled selected>
                                        {language === 'en' ? 'Year' : 'السنة'}
                                    </option>
                                    {years.map((year) => (
                                        <option key={year.id} value={year.id}>{year.year}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 col-xl-2">
                                <button type="submit" className="btn ford-btn" disabled={years.length === 0}>
                                    <span language="ar">ابحث الآن</span>
                                    <span language="en">Search Now</span>
                                </button>
                            </div>
                        </div>                
                    </form>
                </div>
            </div>

            <div className="d-block d-md-none">
                <div className="accordion" id="categoryAccordion">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Change/Select Vehicle
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#categoryAccordion">
                            <div className="accordion-body">
                                <div className="filter-form">
                                    <form onSubmit={handleSearchClick}>
                                        <div className="row">
                                            <div className="col-12 col-md-3 col-lg-3 col-xl-4">
                                                <p className="label">Model</p>
                                                <select className="form-select" aria-label="" onChange={handleModelChange}>
                                                    <option disabled selected>
                                                        {language === 'en' ? 'Model' : 'الطراز'}
                                                    </option>
                                                    {models.map((model) => (
                                                        <option key={model.id} value={model.id}>{model.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-2 col-lg-3 col-xl-2">
                                                <p className="label">Year</p>
                                                <select className="form-select" aria-label="" onChange={handleYearChange}>
                                                    <option disabled selected>
                                                        {language === 'en' ? 'Year' : 'السنة'}
                                                    </option>
                                                    {years.map((year) => (
                                                        <option key={year.id} value={year.id}>{year.year}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-3 col-xl-2 text-center">
                                                <button type="submit" className="btn ford-btn" disabled={years.length === 0}>
                                                    <span language="ar">ابحث الآن</span>
                                                    <span language="en">Search Now</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>                
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>
            </div>

            <div className="accessories">
                <div className="row">
                    <div className="col-6">
                        <h2>{category.name}</h2>
                    </div>
                </div>
                <div className="row gx-5">
                    {loading && accessories.length === 0 &&
                        <div className="loader-container">
                            <div className="loader"><img src='/images/Rolling@1x-1.0s-200px-200px.gif' width={100} height={100} alt="Loading"/></div>
                        </div>
                    }
                    {!loading && accessories.length !== 0 &&
                        accessories.map((accessory) => (
                        <div className="col-12 col-md-6 col-lg-4 col-xl-3" key={accessory.id}>
                            <div className="accessory">
                                <Link to={`/accessory/${accessory.id}/${slugify(accessory.accessory_name)}`}>
                                    {(() => {
                                        const heroImage = accessory.media && accessory.media.find(media => media.collection_name === "print-image");
                                        if (heroImage) {
                                            hasHeroImage = true;
                                            return (
                                                <img 
                                                    src={heroImage.original_url} 
                                                    alt={accessory.accessory_name} 
                                                    />
                                            );
                                        }
                                        return null;
                                    })()}
                                    {!hasHeroImage && <img src="../images/default.jpg" alt="Default" />}
                                    {hasHeroImage = false}
                                </Link>
                                <p className="name">
                                    <Link to={`/accessory/${accessory.id}/${slugify(accessory.accessory_name)}`}>{accessory.accessory_name}</Link>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="pagination">
                <div className="links">
                    {currentPage > 1 && 
                        <span className="prev">
                            <HashLink to="#" onClick={handlePreviousClick}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                                    <path fillRule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                                </svg>
                                <span language="ar">سابق</span>
                                <span language="en">Previous</span>
                            </HashLink>    
                        </span>
                    }
                    <span className="current">
                        {currentPage}
                    </span>
                    {nextPage && 
                        <span className="next">
                            <HashLink to="#" onClick={handleNextClick}>
                                <span language="ar">التالي</span>
                                <span language="en">Next</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"/>
                                    <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"/>
                                </svg>
                            </HashLink>
                        </span>
                    }
                </div>
            </div>
        </div>
    );
}

export default SearchForm;
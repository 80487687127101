import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { HashLink } from 'react-router-hash-link';

export default class New extends React.Component {

    state = {
        accessories: [],
        models: [],
        years: [],
        selectedModel: '',
        selectedYear: '',
        current_page: 1,
        next_page: false,
        loading: true,
    }


    handleSearchClick = (event) => {
        this.setState({ loading: true });  // Set loading to true when the component mounts

        const dealer_id = localStorage.getItem('dealer_id');
        let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
        const config = {
            headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en' }
        };

        this.setState({ current_page: 1 });

        let modelFilter = '';
        if (this.state.selectedModel) {
            modelFilter = '&model_id=' + this.state.selectedModel;
            if (this.state.selectedYear) {
                modelFilter += '&year_id=' + this.state.selectedYear;
            }
        }

        axios.get(
            process.env.REACT_APP_FORD_API_URL + '/api/v1/accessories/filter?dealer_id=' + dealer_id + '&per_page=3' + modelFilter + '&brand_id=' + process.env.REACT_APP_BRAND_ID + '&market_id=' + process.env.REACT_APP_MARKET_ID + '&order_by=updated_at&sorder_by_dir=desc',
            config
        )
            .then(res => {
                const accessories = res.data.data;
                this.setState({ accessories });
                if (res.data.next_page_url) {
                    this.setState({ next_page: true });
                    this.setState({ loading: false });  // Ensure loading is set to false even if there is an error
                } else {
                    this.setState({ next_page: false });
                    this.setState({ loading: false });  // Ensure loading is set to false even if there is an error
                }
            })

        event.preventDefault();
    };


    handleModelChange = (event) => {
        this.setState({ loading: true });  // Set loading to true when the component mounts

        let selectedModel = event.target.value;
        this.setState({ selectedModel });
        localStorage.setItem('selectedModel', selectedModel);
        const dealer_id = localStorage.getItem('dealer_id');

        let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
        const config = {
            headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en' }
        };
        axios.get(
            process.env.REACT_APP_FORD_API_URL + '/api/v1/car-models/' + selectedModel + '?dealer_id=' + dealer_id + '&brand_id=' + process.env.REACT_APP_BRAND_ID + '&market_id=' + process.env.REACT_APP_MARKET_ID,
            config
        )
            .then(res => {
                const years = res.data.years;
                this.setState({ years });
                this.setState({ loading: false });  // Ensure loading is set to false even if there is an error
            })
    };

    handleYearChange = (event) => {
        this.setState({ loading: true });  // Set loading to true when the component mounts

        let selectedYear = event.target.value;
        this.setState({ selectedYear });
        this.setState({ loading: false });  // Ensure loading is set to false even if there is an error
        localStorage.setItem('selectedYear', selectedYear);
    };

    componentDidMount() {
        this.setState({ loading: true });  // Set loading to true when the component mounts
        const dealer_id = localStorage.getItem('dealer_id');

        let token = 'Bearer ' + process.env.REACT_APP_FORD_API_TOKEN;
        const config = {
            headers: { 'Authorization': token, 'X-Locale': localStorage.getItem('language') || 'en' }
        };

        axios.get(
            process.env.REACT_APP_FORD_API_URL + '/api/v1/accessories/filter?dealer_id=' + dealer_id + '&per_page=3' + '&brand_id=' + process.env.REACT_APP_BRAND_ID + '&market_id=' + process.env.REACT_APP_MARKET_ID + '&order_by=updated_at&sorder_by_dir=desc',
            config
        )
            .then(res => {
                const accessories = res.data.data;
                this.setState({ accessories });
                if (res.data.next_page_url) {
                    this.setState({ next_page: true });
                    this.setState({ loading: false });  // Ensure loading is set to false even if there is an error
                } else {
                    this.setState({ next_page: false });
                    this.setState({ loading: false });  // Ensure loading is set to false even if there is an error
                }
            })

        axios.get(
            process.env.REACT_APP_FORD_API_URL + '/api/v1/car-models/filter?brand_id=' + process.env.REACT_APP_BRAND_ID + '&market_id=' + process.env.REACT_APP_MARKET_ID,
            config
        )
            .then(res => {
                const models = res.data.data;
                this.setState({ models });
                this.setState({ loading: false });  // Ensure loading is set to false even if there is an error
            })
    }

    slugify(text) {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\p{L}\p{N}\-]+/gu, '')
            .replace(/\-\-+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    }

    render() {
        const { loading } = this.state;

        const { accessories, models, years, current_page, next_page } = this.state;
        let hasHeroImage = false;
        let language = localStorage.getItem('language') || 'en';

        return (
            <div className="container">
                <div className="d-none d-md-block">
                    <div className="filter-form">
                        <form>
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-3 col-xl-3">
                                    <p className="heading">
                                        <span language="ar">نموذج / بحث السنة</span>
                                        <span language="en">Model / Year Lookup</span>
                                    </p>
                                </div>
                                <div className="col-12 col-md-4 col-lg-3 col-xl-3">
                                    <select className="form-select" aria-label="" onChange={this.handleModelChange}>
                                        <option disabled selected>
                                            {language === 'en' && 'Model'}
                                            {language === 'ar' && 'الطراز'}
                                        </option>
                                        {models.map((model, key) => (
                                            <option value={model.id}>{model.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4 col-lg-3 col-xl-3">
                                    <select className="form-select" aria-label="" onChange={this.handleYearChange}>
                                        <option disabled selected>
                                            {language === 'en' && 'Year'}
                                            {language === 'ar' && 'السنة'}
                                        </option>
                                        {years.map((year, key) => (
                                            <option value={year.id}>{year.year}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4 col-lg-2 col-xl-2">
                                    <button type="submit" className="btn ford-btn" disabled={years.length === 0} onClick={this.handleSearchClick}>
                                        <span language="ar">ابحث الآن</span>
                                        <span language="en">Search Now</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="d-block d-md-none">
                    <div className="accordion" id="categoryAccordion">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Change/Select Vehicle
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#categoryAccordion">
                                <div className="accordion-body">
                                    <div className="filter-form">
                                        <form>
                                            <div className="row">
                                                <div className="col-12 col-md-3 col-lg-3 col-xl-4">
                                                    <p className="label">Model</p>
                                                    <select className="form-select" aria-label="" onChange={this.handleModelChange}>
                                                        <option disabled selected>
                                                            {language === 'en' && 'Model'}
                                                            {language === 'ar' && 'الطراز'}
                                                        </option>
                                                        {models.map((model, key) => (
                                                            <option value={model.id}>{model.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-12 col-md-2 col-lg-3 col-xl-2">
                                                    <p className="label">Year</p>
                                                    <select className="form-select" aria-label="" onChange={this.handleYearChange}>
                                                        <option disabled selected>
                                                            {language === 'en' && 'Year'}
                                                            {language === 'ar' && 'السنة'}
                                                        </option>
                                                        {years.map((year, key) => (
                                                            <option value={year.id}>{year.year}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-3 col-xl-2 text-center">
                                                    <button type="submit" className="btn ford-btn" disabled={years.length === 0} onClick={this.handleSearchClick}>
                                                        <span language="ar">ابحث الآن</span>
                                                        <span language="en">Search Now</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="accessories">
                    <div className="row">
                        <div className="col-6">
                            <h2>New Accessories</h2>
                        </div>
                    </div>
                    <div className="row gx-5">
                        {loading && accessories.length === 0 &&
                            <div className="loader-container">
                                <div className="loader"><img src='../images/Rolling@1x-1.0s-200px-200px.gif' width={100} height={100} /></div>
                            </div>
                        }
                        {!loading && accessories.length !== 0 &&
                            accessories.map((accessory, key) => (
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="accessory">
                                        <Link to={"/accessory/" + accessory.id + "/" + this.slugify(accessory.accessory_name)}>
                                            {accessory.media && accessory.media.map((media, index) => {
                                                if (media.collection_name === "print-image") {
                                                    hasHeroImage = true;
                                                    return <img src={media.original_url} />;
                                                }
                                                return null;
                                            })}
                                            {!hasHeroImage && <img src="../images/default.jpg" />}
                                            {hasHeroImage = false}
                                        </Link>
                                        <p className="name">
                                            <Link to={"/accessory/" + accessory.id + "/" + this.slugify(accessory.accessory_name)}>{accessory.accessory_name}</Link>
                                        </p>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>


            </div>
        )
    }
}

